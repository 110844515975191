import React, { useEffect, useState, useRef, useCallback } from "react";
import ReactHtmlParser from "react-html-parser";
import AssignmentStatus from "./AssignmentStatus";
import AssignmentUpload from "./AssignmentUpload";
import AssessmentHeader from "./AssessmentHeader";
import Step1 from "./assessmentDetail/Step1";
import Step2 from "./assessmentDetail/Step2";
import Step3 from "./assessmentDetail/Step3";
import Step4 from "./assessmentDetail/Step4";
import Step5 from "./assessmentDetail/Step5";
import Step6 from "./assessmentDetail/Step6";
import Step7 from "./assessmentDetail/Step7";
import ObjectDetection from '../../pages/object-detection/object-detection';
import Countdown, { zeroPad } from "react-countdown";
import '../../pages/object-detection/styles.css';
import { GetAssignmentDetail, AssignmentSubmission, SetToFinal, RemoveFile, UploadIdentificationImage, StoreTime, RetryQuiz } from "../../services/QualificationService";
import Swal from "sweetalert2";
import { GetFileSize, getRandomInt } from '../../utils/commonFunction.js'
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Str from "../common/Str";
import AudioDetection from "../../pages/object-detection/audio-detection";
import Exam_recording from '../../pages/object-detection/Exam_recording'
import { useHistory } from "react-router-dom";
import { BASE_URL, DOWNLOAD_FILE_URI, STUDENT_FILE_URI, zipURL } from "../../utils/Constants";
import { setIsExamLaunched } from "../../store/actions";

const RefreshSeconds = 25000;
var camStream = {}
var micStream = {}

const Assignment = (props) => {
  const { assessmentId } = props;
  const [assignmentDetails, setassignmentDetails] = useState({});
  const [assignmentReload, setAssignmentReload] = useState(false);
  const [step, setStep] = useState(0);
  const [hasCamera, setHasCamera] = useState(false);
  const [hasMicrophone, setHasMicrophone] = useState(false);
  const [isRecord, setIsRecord] = useState(false);
  const [currentMedia, setCurrentMedia] = useState({});
  const [photoId, setPhotoId] = useState("");
  const [showIdentification, setShowIdentification] = useState("");
  const [showSystemCheck, setShowSystemCheck] = useState("");
  const [toolTip, setToolTip] = useState("");
  const [checkShare, setCheckShare] = useState(false);
  const [count, setCount] = useState(0);
  const [timesLeft, setTimesLeft] = useState(0);
  const [soundCount, setSoundCount] = useState(0);
  const [soundCountExam, setSoundCountExam] = useState(0);
  const [noCameraPermission, setNoCameraPermission] = useState('');
  const [cameraPermission, setCameraPermission] = useState('');
  const [resetCount, setResetCount] = useState(0);
  const [percent, setPercent] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const [total, setTotal] = useState(0);
  const [quizArr, setQuizArr] = useState([])
  const [quizId, setQuizId] = useState(null)
  const [quiz, setquiz] = useState({});
  const [result, setresult] = useState(false);
  const [maxAttempt, setMaxAttempt] = useState(0);
  const [totalattempt, setTotalattempt] = useState(0);
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const [assPassMark, setAssPassMark] = useState("");
  const [quizTotalTime, setQuizTotalTime] = useState('0 hour 0 min');
  const [isTimer, setIsTimer] = useState(false);
  const [subId, setSubId] = useState(null);
  const [downloadFileSize, setDownloadFileSize] = useState(null);
  const dispatch = useDispatch();
  const { examLaunched } = useSelector(state=>state.examLaunched);
  const [submitQuizAssessment, setSubmitQuizAssessment] = useState(false);
  const [fileSubmitted, setFileSubmitted] = useState(true);
  const [loadFinal, setLoadFinal] = useState(false);

  const brandConfig = useSelector((state) => state.brand);
  var brandInfo = brandConfig.configuration
    const updateSubmitQuizAssessment = () => {
    setSubmitQuizAssessment(false);
  }
  useEffect(() => {
    if (props.assessmentId) {
      let aid = props.assessmentId;
      GetAssignmentDetail({ aid })
        .then((res) => {
          props.setIsSubmitDocsAss(false);
          // check hybrid question
          setSubId(res?.data?.subID);
          setAssPassMark(res?.data?.pass_mark);
          let isHybrid = res?.data?.isHybrid;
          // const hybridTypeQues = ["Short Answer", "Long Answer", "Document Upload"];
          // if(res?.data?.Quiz){
          //   for (let i = 0; i < Object.values(res?.data?.Quiz?.reduxData).length; i++) {
          //     if (hybridTypeQues.includes(Object.values(res?.data?.Quiz?.reduxData)[i].question_type)) {
          //       isHybrid = true;
          //       break;
          //     }
          //   }
          // }

          if (res && res.data && res.data.quiz_id) {
            // console.log('all the question of the quiz are ', res.data.Quiz.questionsGroup)
            // setQuizArr(res.data.Quiz.questionsGroup)
            setQuizId(res.data.quiz_id)
            setMaxAttempt(res.data.maximum_attempts)
            setTotalattempt(res.data.totalattempt)
          }else{
            setQuizId(null)
            setMaxAttempt(0)
            setTotalattempt(0)
          }
          if(res?.data?.launched == 0){
          if (res.data.status == "Draft") {
            if ((res.data.camera ? hasCamera ? false : true : false) || (res.data.audio ? hasMicrophone ? false : true : false) || (res.data.screen_share ? isRecord ? false : true : false)) {
              setStep(2);
              setTimeout(() => {
                repeatSysCheck('repeat')

              }, 3000);
            }
            else {
              setStep(5);
            }
          } else if (
            res.data.status == "Submitted" ||            
            (res.data.status == "Closed-Submitted" && (res.data.quiz_id == 0 || res.data.quiz_id == null))  ||
            (res.data.status == "Closed-Submitted" && isHybrid)
          ) {
            setStep(6);
          } else if (
            res.data.status == "Closed-Marked" ||
            res.data.status == "Closed Not-Submitted" 
          ) {
            if(res.data.notify_assessed==1){
              setStep(7);
            }else{
              setStep(6)
            }
          } else {
            if (step == 4) {
              setStep(5)
            }
            else if (res.data.status == "Pending" || res.data.camera || res.data.audio || res.data.screen_share || res.data.identification) {
              setStep(1);
            }
            // else if ((res.data.remainingDuration > 0 && res.data.timeLimitActive == 1) || (res.data.remainingDuration == 0 && res.data.timeLimitActive == 0)) {
            else if (res.data.quiz_id == 0) {
              setStep(5)
            }
            else {
              setStep(4)
            }
          }
          }

          let timePercent = 0
          if (res.data.timeLimitActive) {
            let timeLeft = (res.data.timeHour * 60) + res.data.timeMin
            timePercent = (timeLeft * 10) / 100
          }
          setTimesLeft(timePercent)
          setQuizTotalTime(`${res.data.timeHour || 0} hour ${res.data.timeMin || 0} min`);
          setassignmentDetails(res.data);
          let identification = res.data.identification;
          let sysCheck =
            res.data.camera || res.data.audio || res.data.screen_share;
          setShowIdentification(identification ? true : false);
          setShowSystemCheck(sysCheck ? true : false);
          let toolStr =
            "This is a protected exam, to begin this exam you will require " +
            (res.data.camera ? "operational webcam" : "") +
            (res.data.camera && res.data.audio && res.data.screen_share
              ? ""
              : !res.data.camera && (res.data.audio || res.data.screen_share)
                ? ""
                : " ") +
            (res.data.camera && res.data.audio && res.data.screen_share
              ? ", "
              : res.data.camera && res.data.audio && !res.data.screen_share
                ? "and "
                : "") +
            (res.data.audio ? "microphone " : "") +
            ((res.data.camera || res.data.audio) && res.data.screen_share
              ? "and "
              : "") +
            (res.data.screen_share ? "share your screen" : "");
          setToolTip(sysCheck ? toolStr : "This assignment has no conditions");
          setCheckShare(res.data.screen_share ? true : false);
          if(res?.data?.launched == 1){
            if(res?.data?.quiz_id && res?.data?.remainingDuration > 0){              
              setStep(4);
            } else {
              setStep(5);
            }
            props.setIsExamLaunchedToTrue();
            dispatch(setIsExamLaunched({isExamLaunched:true, isClicked:false, url: history.location.pathname}));
          } else {
            props.setIsExamLaunchedToFalse();
            dispatch(setIsExamLaunched({}));

          }
          // console.log("___________RUN ASS")
          // (async ()=>{
          //   let f = await GetFileSize(res.data.assFile.includes("https") ? res.data.assFile : BASE_URL + "/" + res.data.assFile.replaceAll("/home/myaie/public_html/","").replace("public/","").replace("null/",""));
          //   setDownloadFileSize(f);
          // })();
        })
        .catch((error) => {
          console.error(error);
          history.push(`/course/${props.courseId}/subject/${props.subjectId}`);
          props.setTab("assessment_latest");
          props.setShowAssignment(false);
         });
    }
  }, [assessmentId,assignmentReload, props.assessmentId]);

  useEffect(() => {
    return () => {
      const tempCamstream = (camStream && camStream.id) ? camStream : undefined;
      const camtracks = tempCamstream && tempCamstream.getTracks();
      camtracks && camtracks.forEach(function (track) {
        track.stop();
      });

      const tempMicstream = (micStream && micStream.id) ? micStream : undefined;
      const mictracks = tempMicstream && tempMicstream.getTracks();
      mictracks && mictracks.forEach(function (track) {
        track.stop();
      });

      const shareStream = currentMedia && currentMedia.stream ? currentMedia.stream : undefined
      shareStream && shareStream.stream && shareStream.stream.getTracks()
        .forEach(track => track.stop())
      setCurrentMedia({})

      micStream = {}
      camStream = {}
    }
  }, [])

  const nextClick = () => {
    let currentStep = step
    if (currentStep == 1) {
      if (showSystemCheck) {
        setStep(step + 1)
      }
      else if (showIdentification && (!showSystemCheck)) {
        setStep(3)
      }
      else {
        setStep(4)
      }
    }
    else {
      setStep(step + 1)
    }
  }

  useEffect(() => {
    if ((step > 1) && (step < 6)) {
      if ((assignmentDetails.camera || assignmentDetails.identification)) {
        detectWebcam(function (hasWebcam) {
          setHasCamera(hasWebcam ? true : false);
        });
      }
      if (assignmentDetails.audio) {
        detectAudio();
      }
      if (checkShare) {
        if ((!currentMedia?.state) || (currentMedia?.state == "inactive")) {
          setIsRecord(false)
          checkShareFun()
          // start()
        }
      }
    }
  }, [step > 1 && step < 6 && count]);

  // useEffect(() => {
  //   checkShareFun()
  // }, [step]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count => count + 1)
    }, RefreshSeconds);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  useEffect(() => {
    setResetCount(getRandomInt(5000))
    setSoundCount(0)
  }, [step]);

  const Download = async (fileUrl, fileName) => {
    if (fileUrl){
      //remove http:// or https:// from fileURL if it has it
      fileUrl = fileUrl.replace(/(^\w+:|^)\/\//, '');
    }
    document.getElementById('modalbtn').click()
    let flag = 0;
    await axios({
      url: fileUrl.includes(STUDENT_FILE_URI) ? fileUrl.replace(STUDENT_FILE_URI, DOWNLOAD_FILE_URI) : zipURL + fileUrl,
      method: "GET",
      responseType: "blob", // important
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total); // you can use this to show user percentage of file downloaded
        setPercent(percentCompleted.toString())
        setLoaded(loaded ? Math.round(loaded / 1024 / 1024) : '')
        setTotal(total ? Math.round(total / 1024 / 1024) + ' MB' : '')
      }
    }).then((response) => {
      setTimeout(() => {
        document.getElementById('modalbtn').click()
      }, 500);
      if (response.status == 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        flag = 1;
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Downloading Finished",
          showConfirmButton: true
        }).then((result) => {
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error while downloading file",
        }).then((result) => { });
      }

    }).catch((error) => {
      setTimeout(() => {
        document.getElementById('modalbtn').click()
      }, 500);
      Swal.fire({
        icon: "error",
        title: "Error while downloading file",
      }).then((result) => { });
    });
    if (flag) return flag;
  }

  const checkShareFun = () => {
    if (checkShare && (step == 2)) {
      if ((!currentMedia?.state) || (currentMedia?.state == "inactive")) {
        // count += 1
        start()
      }
    }
    else if (checkShare && (step > 2) && (step < 6)) {
      if ((!currentMedia?.state) || (currentMedia?.state == "inactive")) {
        setIsRecord(false)
      }
    }
  }

  const repeatSysCheck = async (next) => {
    let sound = false
    let cam = false
    if (assignmentDetails.camera) {
      await detectWebcam(function (hasWebcam) {
        setHasCamera(hasWebcam ? true : false);
        cam = hasWebcam ? true : false
      });
    }
    if (assignmentDetails.audio) {
      sound = await detectAudio();
    }
    if (assignmentDetails.screen_share) {
      if ((!currentMedia?.state) || (currentMedia?.state == "inactive")) {
        setIsRecord(false)
        start()
      }
    }
    setTimeout(() => {
      if ((assignmentDetails.camera ? cam : true) && (assignmentDetails.audio ? sound : true)) {
        moveNext(next)
      }
    }, 2000);

  }



  const moveNext = async (next) => {
    if ((next == 'next') && (assignmentDetails.camera ? hasCamera : true) && (assignmentDetails.audio ? hasMicrophone : true) && (assignmentDetails.screen_share ? isRecord : true)) {
      if (assignmentDetails.identification) {
        setStep(step + 1)
      }
      else {
        setStep(4)
      }

    }
  }

  async function detectWebcam(callbck) {
    let sd = await navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        camStream = stream
        return 'success'
      })
      .catch((err) => {
        console.log(err);
        return 'fail'

      });
    if (sd == 'success') {
      callbck(true)
    }
    else {
      callbck(false)
    }
  }

  async function detectAudio() {
    let sd = await navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        micStream = stream
        setHasMicrophone(true);
        return 'success'
      })
      .catch((err) => {
        console.log(err);
        setHasMicrophone(false);
        return 'fail'

      });
    if (sd == 'success') {
      return true
    }
    else {
      return false
    }
  }

  var mediaRecorder

  async function start() {
    let stream = await recordScreen();
    let mimeType = "video/webm";
    mediaRecorder = createRecorder(stream, mimeType);
    setCurrentMedia(mediaRecorder)
    setIsRecord(true)
  }

  async function stop(isSaved) {
    if (props.isExamLaunched && !isSaved) {
      Swal.fire({
        title: 'Are you sure you want to exit?',
        text: "The countdown will continue even if this tab is closed",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Leave'
      }).then(function (result) {
        if (result.isConfirmed) {
      props.setTab("assessment_latest")
      currentMedia && currentMedia.stream && currentMedia.stream.getTracks()
        .forEach(track => track.stop())
      setIsRecord(false)
      setCurrentMedia({})
      // props.setIsExamLaunchedToFalse()
      dispatch(setIsExamLaunched({...examLaunched, isExamLaunched: false, isClicked: false }));
      props.parentCall(); 
        }
      })
    } else {
      props.setTab("assessment_latest")
      currentMedia && currentMedia.stream && currentMedia.stream.getTracks()
        .forEach(track => track.stop())
      setIsRecord(false)
      setCurrentMedia({})
    }
  }

  const leaveExamPopup = () => {
    if (examLaunched?.isExamLaunched) {
      Swal.fire({
        title: 'Are you sure you want to exit?',
        text: "The countdown will continue even if this tab is closed",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continue'
      }).then(function (result) {
        if (result.isConfirmed) {
          // if(assignmentDetails.quiz_id > 0){
          //   setSubmitQuizAssessment(true);
          // } else {
          //   setToFinal(assignmentDetails, setAssignmentReload, true);
          // }
          currentMedia && currentMedia.stream && currentMedia.stream.getTracks()
            .forEach(track => track.stop())
          setIsRecord(false)
          setCurrentMedia({})        
          // props.setIsExamLaunchedToFalse()
          dispatch(setIsExamLaunched({...examLaunched, isExamLaunched: false, isClicked: false }));
        }
      })
    }
  }

  useEffect(()=>{
    // console.log("_________EXAM___REDUX___STATE_______",examLaunched);
    if(assignmentDetails?.timeLimitActive==0){
      dispatch(setIsExamLaunched({isExamLaunched:false, isClicked:true}));
    }else if(examLaunched?.isClicked){
      leaveExamPopup();
    }
    return ()=>{
      dispatch(setIsExamLaunched({...examLaunched, isClicked: false}));
    }
  },[examLaunched?.isClicked])

  async function recordScreen() {
    return await navigator.mediaDevices.getDisplayMedia({
      audio: true,
      video: { mediaSource: "screen" },
    });
  }

  function createRecorder(stream, mimeType) {
    // the stream data is stored in this array
    let recordedChunks = [];

    const mediaRecorder = new MediaRecorder(stream);

    mediaRecorder.ondataavailable = function (e) {
      if (e.data.size > 0) {
        recordedChunks.push(e.data);
      }
    };
    mediaRecorder.onstop = function () {
      // saveFile(recordedChunks);
      setIsRecord(false)
      recordedChunks = [];
    };
    mediaRecorder.start(200); // For every 200ms the stream data will be stored in a separate chunk.
    return mediaRecorder;
  }

  function saveFile(recordedChunks) {
    const blob = new Blob(recordedChunks, {
      type: "video/webm",
    });
    let filename = window.prompt("Enter file name"),
      downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `${filename}.webm`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    URL.revokeObjectURL(blob); // clear from memory
    document.body.removeChild(downloadLink);
  }

  function handleTakePhoto(dataUri) {
    // Do stuff with the photo...
    fetch(dataUri)
      .then(res => res.blob())
      .then(res => {
        const formInputs = new FormData();
        formInputs.append('assignment_id', assignmentDetails.id);
        formInputs.append('photo', res);
        UploadIdentificationImage(formInputs)
          .then((res) => {
            setPhotoId(dataUri)
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Submission Could not be Completed.",
            }).then((result) => { });
          });
      })
  }

  function handleTakePhotoAnimationDone(dataUri) {
    // Do stuff with the photo...
    // console.log('takePhoto');
  }

  function handleCameraError(error) {
    console.log('handleCameraError', error);
  }

  const handleClick = () => {
    // console.log('the value of cameraref is ', document.getElementById('outer-circle').click())
  }

  const retake = () => {
    setPhotoId("")
  }


  const getUploadParams = ({ meta }) => {
    return {
      url: 'https://httpbin.org/post'
    }
  }


  const handleSubmit = (files, allFiles, row, setAssignmentReload) => {
    setFileSubmitted(false);
    Swal.close();
    Swal.fire({
      icon: "info",
      title: 'Processing File...',
      text: 'Upload may take a bit longer than usual',
      showConfirmButton: false
    });
    if(files.length){
      const formInputs = new FormData();
      files.map((f, index) => {
        formInputs.append('file_' + index, f.file)
      })
      formInputs.append('assignmentRow1', JSON.stringify(row));
      AssignmentSubmission(formInputs)
        .then((res) => {
          setAssignmentReload(getRandomInt(5000));
          Swal.close();
          Swal.fire({
            icon: "success",
            title: 'Submission Received',
            text: 'To Finalize your submission please set the status to Final.',
            showConfirmButton: true
          }).then((result) => {
          });
          setFileSubmitted(true);
        })
        .catch((error) => {
          if(error.response && error.response.status && error.response.status ==400 && error.response?.data?.alreadySubmitted==true){
            Swal.fire({
              icon: "info",
              title: "Assessment already submitted",
              text: error.response.data.message,
            }).then(()=>{
              initiateReload()
            })
          }else{
            Swal.fire({
              icon: "error",
              title: "Submission Could not be Completed.",
            }).then((result) => {setFileSubmitted(true);});
          }
        });
    }else{
      Swal.close();
      setFileSubmitted(true);
    }
    // stop()
    allFiles.forEach(f => f.remove())
  }

  const handleRemoveFile = (rowId, type, setAssignmentReload, path) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        RemoveFile({ rowId, type, path })
          .then((res) => {
            setAssignmentReload(getRandomInt(5000));
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Files has been removed successfully",
            }).then((result) => {
              setStep(5)
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
    return (
      <div>
        {previews}

        <div {...dropzoneProps}>
          {files.length < maxFiles && input}
        </div>

        {files.length > 0 && submitButton}
      </div>
    )
  }

  const setToFinal = (row, setAssignmentReload, timeUp) => {
    setLoadFinal(true);
    if(timeUp){
      dispatch(setIsExamLaunched({}));
      props.setIsExamLaunchedToFalse();
      SetToFinal({ subID: row.subID === null ? 0 : row.subID, subject: row.subject, assignment: row.id })
      .then((res) => {
        setAssignmentReload(getRandomInt(5000));
        stop()
        Swal.close();        
        Swal.fire({
          icon: "success",
          title: 'Status has been changed',
          showConfirmButton: true
        }).then((result) => { setStep(step + 1) });
      })
      .catch((error) => {
        if(error.response.status==400 && error?.response?.data?.alreadySubmitted==true){
          Swal.fire({
            icon: "info",
            title: "Assessment already submitted",
            text: error.response.data.message,
          }).then(()=>{
            initiateReload()
          })
        }else{
          Swal.fire({
            icon: "error",
            title: "Status could not be changed.",
          }).then((result) => { });
        }
      }).finally(()=>{
        setLoadFinal(false);
      });
    } else {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to change the submission to Final Status? You will not be able to edit the submission once you do this.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'I am sure'
    }).then(function (result) {
      if (result.isConfirmed) {
        dispatch(setIsExamLaunched({}));
        props.setIsExamLaunchedToFalse();
        //console.log(row,row.subID,row.subject);
        SetToFinal({ subID: row.subID === null ? 0 : row.subID, subject: row.subject, assignment: row.id })
          .then((res) => {
            setAssignmentReload(getRandomInt(5000));
            stop()
            Swal.close();            
            Swal.fire({
              icon: "success",
              title: 'Status has been changed',
              showConfirmButton: true
            }).then((result) => { setStep(step + 1) });
          })
          .catch((error) => {
            if(error.response.status==400 && error?.response?.data?.alreadySubmitted==true){
              Swal.fire({
                icon: "info",
                title: "Assessment already submitted",
                text: error.response.data.message,
              }).then(()=>{
                initiateReload();
              })
            }else{
              Swal.fire({
                icon: "error",
                title: "Status could not be changed.",
              }).then((result) => { });
            }
          }).finally(()=>{
            setLoadFinal(false);
          });
      }
    }).finally(()=>{
      setLoadFinal(false);
    });
  }
  };

  useEffect(()=>{
    if(props.isSubmitDocsAss !== null && props.isSubmitDocsAss && props.isExamLaunched){
      setToFinal(assignmentDetails, setAssignmentReload, true);
    }
  },[props.isSubmitDocsAss]);

  const downloadFile = async (absolutePath, fileName, index, timeLimitActive, setAssignmentReload, startTime, path_size = '25,b') => {
    if (timeLimitActive == 1) {
      // let msg = "You are about to launch your assessment which will start the countdown for submission. Please take note of the time you will have to submit your assessment once launched!";
      let msg = "Do you want to launch the assessment and download the assessment files?";
      if (startTime === null) {
        Swal.close();
        Swal.fire({
          title: 'Are you sure?',
          text: msg,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Launch',
          html: `${msg} <br/><br/><b>File Size: ${path_size}</b>`
        }).then(function (result) {
          if (result.value) {
            StoreTime({ assId: index })
              .then((res) => {
                Swal.close();
                Swal.fire({
                  icon: "success",
                  title: 'Assessment Launched',
                  showConfirmButton: true
                }).then(async (result) => {
                  let value = false;
                  if (absolutePath == '') {
                    value = true
                  }
                  else {
                    value = await Download(absolutePath, fileName);
                  }
                  if (value) {
                    setAssignmentReload(getRandomInt(5000));
                    setStep(5)
                  }
                });
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Assessment Could not be Started.",
                  text: error,
                }).then((result) => { });
              });
          }
        });
      } else {
        let value = false;
        if (absolutePath == '') {
          value = true
        }
        else {
          value = await Download(absolutePath, fileName);
        }
        if (value) {
          setStep(5)
        }
      }
    } else {
      let value = false;
      if (absolutePath == '') {
        value = true
      }
      else {
        value = await Download(absolutePath, fileName);
      }
      if (value) {
        setStep(5)
      }
    }
  };

  const downloadOnly = async (absolutePath, fileName, index, timeLimitActive, setAssignmentReload, startTime, path_size = '25mb', isLaunchedAndDownload, isOnlyDownload) => {
    setLoader(true);
    if(isLaunchedAndDownload){
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to launch the assessment and download the assessment files?",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'I am sure',
      html: `Do you want to download assessment files? <br/><br/><b>File Size: ${path_size}</b>`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        props.setIsSubmitDocsAss(false);
        props.setIsExamLaunchedToTrue()
        setIsTimer(true);
        dispatch(setIsExamLaunched({...examLaunched, isExamLaunched:true}));
        StoreTime({ assId: index })
          .then((res) => {
            Swal.close();
            Swal.fire({
              icon: "success",
              title: 'Assessment Launched',
              showConfirmButton: true
            }).then(async (result) => {
              setAssignmentReload(getRandomInt(5000));
              let value = false;
              if (absolutePath == '') {
                value = true
              }
              else {
                value = await Download(absolutePath, fileName);
              }
              if (value) {
                setAssignmentReload(getRandomInt(5000));
              }
              setLoader(false);
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Assessment Could not be Started.",
              text: error,
            }).then((result) => { });
            setLoader(false);
          });
        // let value = await Download(absolutePath, fileName);
      }
    }).finally(()=>{
      setLoader(false);
    })
  } else if (isOnlyDownload){
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to download the assessment files?",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'I am sure',
      html: `Do you want to download assessment files? <br/><br/><b>File Size: ${path_size}</b>`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await Download(absolutePath, fileName);
      }
      setLoader(false)
    }).finally(()=>{
      setLoader(false);
    })
  } else {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to launch the assessment?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'I am sure',
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(setIsExamLaunched({...examLaunched, isExamLaunched:true}));
        StoreTime({ assId: index })
          .then((res) => {
            Swal.close();
            Swal.fire({
              icon: "success",
              title: 'Assessment Launched',
              showConfirmButton: true
            }).then(async (result) => {
              setAssignmentReload(getRandomInt(5000));
              let value = false;
              if (absolutePath == '') {
                value = true
              }
              if (value) {
                setAssignmentReload(getRandomInt(5000));
              }
              setLoader(false);
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Assessment Could not be Started.",
              text: error,
            }).then((result) => { });
            setLoader(false)
          });
        props.setIsSubmitDocsAss(false);
        props.setIsExamLaunchedToTrue()
        setIsTimer(true);
      }
    }).finally(()=>{
      setLoader(false);
    })
  }
  }

  const stepUpdate = (newStep) => {
    setStep(newStep)
  }

  const reAttemptQuiz = () => {
    if(maxAttempt > totalattempt){
      setStep(4);
    }
    // setLoader(true)
    // RetryQuiz({ assessment_id: props.assessmentId })
    //   .then((res) => {
    //     setAssignmentReload(getRandomInt(5000));
    //     setLoader(false)
    //   })
    //   .catch(err => {
    //     setLoader(false)
    //   })
  }

  const initiateReload = () => {
    setAssignmentReload(getRandomInt(5000));
  }

  // useEffect(() => {
  //   if (props.isSubmiting) {
  //     stop()
  //   }
  // }, [props])


  return (

    <span className="exam-steps">
      {step == 1 && <Step1
        showIdentification={showIdentification}
        showSystemCheck={showSystemCheck}
        toolTip={toolTip}
        onNext={nextClick}
        backBtn={() => { props.parentCall() }}
        data={assignmentDetails}
        quizId={quizId}
        maxAttempt={maxAttempt}
      />}

      {step == 2 && <Step2
        showIdentification={showIdentification}
        showSystemCheck={showSystemCheck}
        toolTip={toolTip}
        onNext={() => repeatSysCheck('next')}
        backBtn={() => { stop(); props.parentCall() }}
        // ObjectDetection={<ObjectDetection />}
        hasCamera={hasCamera}
        hasMicrophone={hasMicrophone}
        isRecord={isRecord}
        repeatBtn={() => repeatSysCheck('repeat')}
        data={assignmentDetails}
        stepUpdate={stepUpdate}
      />}

      {step == 3 && <Step3
        showIdentification={showIdentification}
        showSystemCheck={showSystemCheck}
        toolTip={toolTip}
        onNext={nextClick}
        backBtn={() => { stop(); props.parentCall() }}
        data={assignmentDetails}
        handleTakePhoto={handleTakePhoto}
        handleTakePhotoAnimationDone={handleTakePhotoAnimationDone}
        handleCameraError={handleCameraError}
        photoId={photoId}
        handleClick={handleClick}
        retake={retake}
        stepUpdate={stepUpdate}
        hasCamera={hasCamera}
        hasMicrophone={hasMicrophone}
        isRecord={isRecord}
        noCameraPermission={noCameraPermission}
      />}

      {step == 4 && <Step4
        assessmentId={props.assessmentId}
        showIdentification={showIdentification}
        // ObjectDetection={<ObjectDetection videoWidth={'No'} videoHeight={'No'} soundCount={soundCount} data={assignmentDetails} />}
        showSystemCheck={showSystemCheck}
        toolTip={toolTip}
        onNext={nextClick}
        // backBtn={() => { stop(); props.parentCall() }}
        backBtn={() => { dispatch(setIsExamLaunched({...examLaunched, isClicked: true})); }}
        data={assignmentDetails}
        photoId={photoId}
        launchDownload={({isLaunchedAndDownload,isOnlyDownload}) =>{
          downloadOnly(
            assignmentDetails.assFile.includes("https") ? assignmentDetails.assFile : BASE_URL + "/" + assignmentDetails.assFile.replaceAll("/home/myaie/public_html/","").replace("public/","").replace("null/",""),
            assignmentDetails.assFileName,
            assignmentDetails.id,
            assignmentDetails.timeLimitActive,
            setAssignmentReload,
            assignmentDetails.startTime,
            assignmentDetails.path_size ? assignmentDetails.path_size : downloadFileSize,
            isLaunchedAndDownload,
            isOnlyDownload
          )}}
        stepUpdate={stepUpdate}
        hasCamera={hasCamera}
        hasMicrophone={hasMicrophone}
        isRecord={isRecord}
        brandInfo={brandInfo}
        // soundCount={setSoundCount}
        quizId={quizId}
        setStep={setStep}
        setQuizId={props.setQuizId}
        setshow={props.setshow}
        setrId={props.setrId}
        rId={props.rId}
        desc={props.desc}
        attemp={props.attempt}
        ques={props.ques}
        review={props.review}
        setquiz={setquiz}
        setresult={setresult}
        quiz={quiz}
        result={result}
        setMaxAttempt={setMaxAttempt}
        maxAttempt={maxAttempt}
        setIsExamLaunchedToTrue={props.setIsExamLaunchedToTrue}
        setIsExamLaunchedToFalse={props.setIsExamLaunchedToFalse}
        setAssignmentReload={()=>setAssignmentReload(getRandomInt(5000))}
        loader={loader}
      />}

      {step == 5 && <Step5
        assessmentId={props.assessmentId}
        showIdentification={showIdentification}
        showSystemCheck={showSystemCheck}
        toolTip={toolTip}
        onNext={nextClick}
        backBtn={stop}
        // backBtn={() => { dispatch(setIsExamLaunched({...examLaunched, isClicked: true})); }}
        data={assignmentDetails}
        launchDownload={({isLaunchedAndDownload,isOnlyDownload}) =>{
          downloadOnly(
            assignmentDetails.assFile.includes("https") ? assignmentDetails.assFile : BASE_URL + "/" + assignmentDetails.assFile.replaceAll("/home/myaie/public_html/","").replace("public/","").replace("null/",""),
            assignmentDetails.assFileName,
            assignmentDetails.id,
            assignmentDetails.timeLimitActive,
            setAssignmentReload,
            assignmentDetails.startTime,
            assignmentDetails.path_size ? assignmentDetails.path_size : downloadFileSize,
            isLaunchedAndDownload,
            isOnlyDownload
          )}}
        timesLeft={timesLeft}
        getUploadParams={getUploadParams}
        onSubmit={(files, allFiles) => handleSubmit(files, allFiles, assignmentDetails, setAssignmentReload)}
        handleRemoveFile={(item) =>
          handleRemoveFile((item.id == 0 ? subId : item.id), (item.isInArray && item.id !== 0) ? "file_id" : "sub_id", setAssignmentReload, item.isInArray ? item.path : item.subFilePath)
        }
        setToFinal={(timeUp) => setToFinal(assignmentDetails, setAssignmentReload, timeUp)}
        setAssignmentReload={setAssignmentReload}
        // ObjectDetection={<ObjectDetection videoWidth={'No'} videoHeight={'No'} soundCount={soundCountExam} data={assignmentDetails} />}
        stepUpdate={stepUpdate}
        hasCamera={hasCamera}
        hasMicrophone={hasMicrophone}
        isRecord={isRecord}
        brandInfo={brandInfo}
        quizArr={quizArr}
        quizId={quizId}
        setQuizId={props.setQuizId}
        setshow={props.setshow}
        setrId={props.setrId}
        rId={props.rId}
        // soundCount={setSoundCountExam}
        desc={props.desc}
        attemp={props.attempt}
        ques={props.ques}
        review={props.review}
        setquiz={setquiz}
        setresult={setresult}
        quiz={quiz}
        result={result}
        maxAttempt={maxAttempt}
        initiateReload={initiateReload}
        isSubmiting={props.isSubmiting}
        stop={stop}
        setIsExamLaunchedToFalse={props.setIsExamLaunchedToFalse}
        assPassMark={assPassMark}
        quizTotalTime={quizTotalTime}
        isTimer={isTimer}
        submitQuizAssessment={submitQuizAssessment}
        updateSubmitQuizAssessment={updateSubmitQuizAssessment}
        fileSubmitted={fileSubmitted}
        setFileSubmitted={setFileSubmitted}
        loader={loader}
        loadFinal={loadFinal}
      />}

      {step == 6 && <Step6
        showIdentification={showIdentification}
        showSystemCheck={showSystemCheck}
        toolTip={toolTip}
        onNext={nextClick}
        backBtn={() => { stop(); props.parentCall() }}
        data={assignmentDetails}
        quizId={quizId}
        maxAttempt={maxAttempt}
        totalattempt={totalattempt}
        reAttemptQuiz={reAttemptQuiz}
        downloadFile={Download}
      />}

      {step == 7 && <Step7
        showIdentification={showIdentification}
        showSystemCheck={showSystemCheck}
        toolTip={toolTip}
        backBtn={() => { stop(); props.parentCall() }}
        openAppealCall={props.openAppealCall}
        data={assignmentDetails}
        reAttemptQuiz={reAttemptQuiz}
        totalattempt={totalattempt}
        maxAttempt={maxAttempt}
        quizId={quizId}
        gradingStandard={props.gradingStandard}
        downloadFile={Download}
      />}



      {(step > 1 && step <= 5) ?
        <div>
          {(assignmentDetails.camera || assignmentDetails.audio || assignmentDetails.screen_share) ?
            <div className={`textseries-content-records ${(step == 2 || step == 3) ? 'd-none' : ''}`}>
              <div className="row">
                <div className="col-md-6 mb-15">
                  <div className="audio-video-box">
                    <div className="sound-wave-recognize">
                      <figure>
                        {(assignmentDetails.camera && hasCamera) ?
                          <Exam_recording />
                          : null
                        }
                      </figure>
                    </div>
                    {(assignmentDetails.audio && hasMicrophone) ?
                      <AudioDetection brandInfo={brandInfo} soundCount={setSoundCount} resetCount={resetCount} />
                      : null}
                  </div>
                </div>
                <div className="col-md-6 mb-15">
                  {(assignmentDetails.camera || assignmentDetails.audio || assignmentDetails.screen_share) ?
                    <ObjectDetection videoWidth={'No'} videoHeight={'No'} soundCount={soundCount} data={assignmentDetails} hasCamera={hasCamera}
                      resetCount={resetCount} />
                    : null}
                </div>
              </div>
            </div>
            : null
          }
        </div>
        :
        null
      }
      <button type="button" className="btn btn-primary d-none" data-toggle="modal" id='modalbtn' data-target="#exampleModal">Launch demo modal</button>

      <div className="downloading-progress modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="swal2-header">
                <div className="swal2-icon swal2-info swal2-icon-show d-flex">
                  <div className="swal2-icon-content">i</div>
                </div>
                <h2 className="swal2-title d-flex mb-4">Downloading...</h2>
                <div className="progress w-100 mb-2">
                  <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100" style={{ width: `${percent}%` }}>{percent}%</div>
                </div>
                {loaded}/{total}
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
}
export default Assignment;