import React from "react";
import { BASE_URL, zipURL } from "../../utils/Constants";
import { TrimText } from "./TrimText";
import Swal from "sweetalert2";
import { download } from "../../utils/FileDownload";

export const RenderAttachList = (props) => {
    const downloadFile = (filePath, fileName) => {
        // console.log('the url received is ', filePath)
        // window.open(filePath, '_blank')

        // const url = (filePath.includes("http") ? "" : BASE_URL) + filePath.replace("/home/myaie/public_html","").replace("public/", "");
        // const link = document.createElement("a");
        // link.href = url;
        // link.className='hold-show'
        // link.setAttribute("download", fileName);
        // link.setAttribute('target', '_blank');
        // document.body.appendChild(link);
        // link.click();

        Swal.fire({
          title: "File downloading",
          onOpen: function () {
              Swal.showLoading();
          },
      });
      
      let fileURL = filePath;

      // if(!fileURL.includes("chat.myaie.ac")){
      //     fileURL = API_BASE.replace("/api/v1", "") + "/" + row.attachment.replace("/home/myaie/api/public_html/", "").replace("/home/myaie/public_html/", "").replace("public/", "")
      // }
      download(fileURL, fileName)

    }
    const openFile = (filePath) => {
      if (!filePath?.includes(zipURL)) {
          filePath = filePath?.replace(zipURL, "");
          //add https:// to start of filepath if it is not there
          
      }
      window.open(filePath, "_blank"); // Open file in a new tab
    };

    const fetchIcon = (path) => {
        let ext = path !== undefined && path?.split('.')
        switch (ext[ext?.length - 1]) {
          case 'docx':
            return 'fal fa-file-word';
            break;
          case 'zip':
            return 'fal fa-file-archive';
            break;
          case 'pdf':
            return 'fal fa-file-pdf';
            break;
          case 'xlsx':
            return 'fal fa-file-excel';
            break;
          case 'jpg':
          case 'jpeg':
          case 'png':
            return 'fal fa-file-image';
            break;
          case 'txt':
            return 'fal fa-file-alt';
            break;
          case 'pptx':
            return 'fal fa-file-powerpoint';
            break;
          default:
            return 'fal fa-file';
        }
      }
      
    return (<div className="uploded-files-item feed-pad">
            <div className="uploded-files-item-txt curser"
                data-toggle="tooltip"
                data-placement="right"
                onClick={() => openFile(props.filePath, props.fileName)}
                title={props.fileName}
               >
                <figure>
                    <i className={fetchIcon(props.filePath)}></i>
                </figure>
                <figcaption >
                    <h4 className="oneline-ellipsis font12">{props.fileName}</h4>
                    <p className="font12 hold-show">{props.size}</p>
                </figcaption>
            </div>

            {props.showClose ?
                <button onClick={() => { props.handleRemoveFile(props) }} type="button" className="btn file-remove-btn"><i className="fal fa-times hold-show"></i></button>
                :
                <a onClick={(e) => {e.stopPropagation(); downloadFile(props.filePath, props.fileName)}} className="btn file-remove-btn rm-box-shadow download-icon"><i className="fal fa-download download-icon"></i></a>
            }
        </div>
    )
}