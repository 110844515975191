import React, { useEffect, useState } from "react";

export default function DownloadCertificate({
  item,
  canRemove,
  handleRemoveFile,
  canEdit,
  handleFileEdit
}) {
  const [fileName, setFileName] = useState(null);
  useEffect(() => {
    const fileSplitArray = item.url.split("/");
    const fileDisplayName = fileSplitArray[fileSplitArray.length - 1];
    setFileName(fileDisplayName);
  }, [item]);

  return (
    <>
     
      <a className="profile-file-name" href={item.file} target="_blank" title={fileName}>
      <i className="fal fa-file"></i>  {fileName}
      </a>
      {canEdit ? (        
        <button type="button" className="btn btn-primary rounded-circle atttach-file">
          <label>
            <i className="fal fa-paperclip"></i>
            <input
              type="file"
              className="form-control  form-control-aatch-file"
              onChange={(e) => handleFileEdit(e)}
            />
          </label>
        </button>
      ) : null}
      {canRemove ? (
        <button
          type="button"
          className="btn btn-danger rounded-circle"
          onClick={() => handleRemoveFile(item.id)}
          title="Delete"
        >
        <i className="fal fa-trash-alt"></i>
        </button>
      ) : null}
    </>
  );
}
