import React from "react";
import { connect } from "react-redux";
import { setUnreadNotificationCount, setWidget, unsetWidget } from "../store/actions";

import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";

import HomeSlider from "../components/common/HomeSlider";
import HomeLinks from "../components/common/HomeLinks";
import HomeWishes from "../components/common/HomeWishes";
import { loadWidgetChat }  from '../utils/commonFunction.js';

/** map actions */
function mapDispatchToProps(dispatch) {
  return {
    setUnreadNotificationCount: (payload) => dispatch(setUnreadNotificationCount(payload)),
    setWidget: (payload) => dispatch(setWidget(payload)),
    unsetWidget: (payload) => dispatch(unsetWidget())
  };
}

/** map state */
const mapStateToProps = (state) => ({ notification: state.notification, user: state.user, widget: state.widget });

class HomePage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let user = this.props.user;
    let widget = this.props.widget;
    let locationState = this.props.location.state;

    if(user && Object.keys(user).length > 0 && widget && Object.keys(widget).length > 0) {
      if(widget.status == 1) {
        // loadWidgetChat(
        //   user.first_name + " " + user.last_name,
        //   user.email,
        //   user.id
        // )
      }
    }

    const unreadNotificationCount= localStorage.getItem('unreadNotificationCount');
    this.props.unsetWidget();
    this.props.setWidget({ status: 2 });
    this.props.setUnreadNotificationCount(unreadNotificationCount ? unreadNotificationCount : 0);
  }

  render() {
    return (
      <>
        <Header></Header>
        <div className="content-wrapper d-flex flex-wrap">
          <SideBar></SideBar>
          <div className="sidebar-right">
            <div className="home-list-wrap">
              <HomeWishes />
              <HomeSlider></HomeSlider>
              <HomeLinks></HomeLinks>              
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
