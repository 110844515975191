import React, { useEffect, useState, useRef } from "react";
import "react-custom-scroll/dist/customScroll.css";
import { ADMIN_PASSWORD } from "../utils/Constants";
import { GetQuestionsPreview } from "../services/QuizService";
import { clearQuiz, setQuizComplete } from "../store/actions";
import { useDispatch } from "react-redux";
import PreviewQuestionsExam from "../components/course/quiz/PreviewQuestionsExam";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import ReviewQuiz_New from "../components/course/quiz/ReviewQuiz_New";

const QuizPreview = (props) => {
    const { match } = props;
    const QuizId = match.params.qid;
    const dispatch = useDispatch();
    const [isQuiz, setIsQuiz] = useState(true);
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [time, settime] = useState(1);
    const interval = useRef(null);
    const [trigger, setTrigger] = useState(0);
    const [timeLimit, setTimeLimit] = useState('0 hours 0 min');
    const [quesDescriptionData, setQuesDescriptionData] = useState({});
    const [result, setResult] = useState(false);
    const [reviewData, setReviewData] = useState([])

    useEffect(() => {
        setLoading(true);
        GetQuestionsPreview({ quid: QuizId })
            .then((res) => {
                // console.log("____________response", res);
                if (res?.data?.message === "Questions not assigned") {
                    setIsQuiz(true);
                } else {
                    setQuesDescriptionData(res?.data?.description);
                    const hr = res?.data?.description?.time_limit_hours || 0;
                    const mn = res?.data?.description?.time_limit_minutes || 0;
                    const totalSeconds = hr*60*60 + mn*60;
                    settime(totalSeconds);
                    setTimeLimit(`${hr} hours ${mn} min`);
                    setIsQuiz(false);
                    dispatch(clearQuiz());
                    const obj = {};
                    res.data.questions.filter(list=>(list && list.questions && list.questions.length > 0)).map(q => {
                        if (q.type === 0) {
                            obj[`qa${q.questions[0].qid}qa`] = q.questions[0]
                        } else {
                            obj[`qa${q.questions[0].qid}qa`] = q.questions
                        }
                    })

                    localStorage.removeItem("totaltime");
                    for (let i = 0; i < localStorage.length; i++) {
                        let value = localStorage.key(i);
                        if (value.startsWith("@")) {
                            localStorage.removeItem(value);
                        }
                    }
                    res.data.questions.filter(list=>(list && list.questions && list.questions.length > 0)).map(q => {
                        q.questions.map(ques=>{
                            localStorage.setItem(`@qa${ques.qid}qa`, 0);
                        })
                    })
                    // const keys = Object.keys(obj);
                    // keys.map((item, index) => {
                    //     localStorage.setItem(`@${item}`, 0);
                    // });
                    dispatch(setQuizComplete(obj));
                    setLoading(false);
                    interval.current = setInterval(() => {
                        settime((prevstate) => {
                            if (prevstate <= 1) {
                                clearInterval(interval.current);
                                return 0;
                            }
                            return prevstate - 1;
                        });
                    }, 1000);
                }
            })
            .catch((err) => {
                console.log(err);
                setIsQuiz(true);
                setLoading(false);
                clearInterval(interval.current);
            });
    
        return () => {
            clearInterval(interval.current);
        };
    }, []);

    if (time == 0 && trigger === 0) {
        clearInterval(interval.current);
        Swal.fire({
            icon: "success",
            title: "Quiz Submitted",
        }).then(() => {
            setTrigger((trigger) => trigger + 1);
        });
    }

    const fancyTimeFormat = (duration) => {
        // Hours, minutes and seconds
        var hrs = ~~(duration / 3600);
        var mins = ~~((duration % 3600) / 60);
        var secs = ~~duration % 60;

        var ret = "";

        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }

        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return duration > 0 ? ret : '0:00';
    }

    return !loading && (
        <div className="quiz__preview">
            <header className="header">
                <span className="logotext">
                    <strong>Preview Assessment</strong>
                </span>
            </header>
            <div className="quiz__preview__wrapper">
                <div className="sidebar-left"></div>
                <div className="sidebar-right">
                    <div className="test-series-main-block">
                        <div className={"test-series-main-left-blk " + (result ? "w-100" : "")}>
                            {!result && <div className="sub-detail-lft bg-white">
                                <div className="sub-lft-head">
                                    <div className="couses-bx-info-top d-flex align-items-center">
                                        <div className="icon"></div>
                                        <div className="couses-bx-info-right">
                                            <h3>Preview Mode</h3>
                                        </div>
                                    </div>
                                    <div className="subject-list-card card"></div>
                                    <div className="subject-list-card card"></div>
                                </div>
                            </div>}
                            {match.params.password !== ADMIN_PASSWORD ? (
                                <div className="subject-no-content">
                                    <div className="no-img"></div>
                                    <div className="no-content">
                                        <h4>Unauthorized Access</h4>
                                        <p>You don't have access to view this page.</p>
                                    </div>
                                </div>
                            ) : (result ? <div className="sub-detail-rgt full-width-preview">
                                    <div className="lecture-bx-info d-flex align-items-center">
                                        <div className="icon">
                                            <i className="fal fa-eye"></i>
                                        </div>
                                        <div className="lecture-bx-info-rgt">
                                            <h3>Assessment Preview</h3>
                                        </div>
                                    </div>
                                    <h4 className="assessment__name">Assessment Name</h4>
                                    <div className="breadcrumb-btn-blk">
                                        <div className="custom-breadcrumb">
                                            <ul>
                                                <li className="custom-breadcrumb-item active">
                                                    <button>
                                                        <i className="fal fa-home"></i>
                                                    </button>
                                                </li>
                                                <li className="custom-breadcrumb-item">
                                                    <span>System Check</span>
                                                </li>
                                                <li className="custom-breadcrumb-item">
                                                    <span>Student Identification</span>
                                                </li>
                                                <li className="custom-breadcrumb-item">
                                                    <span>Test</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="breadcrumb-btns">
                                            <Link to="/" className="btn btn-primary">
                                                <i className="fal fa-chevron-left"></i> Back
                                            </Link>
                                        </div>
                                    </div>
                                    <ReviewQuiz_New data={reviewData} /> 
                                </div>
                                :<div className="sub-detail-rgt">
                                    <div className="lecture-bx-info d-flex align-items-center">
                                        <div className="icon">
                                            <i className="fal fa-eye"></i>
                                        </div>
                                        <div className="lecture-bx-info-rgt">
                                            <h3>Assessment Preview</h3>
                                        </div>
                                    </div>
                                    <h4 className="assessment__name">Assessment Name</h4>
                                    <div className="breadcrumb-btn-blk">
                                        <div className="custom-breadcrumb">
                                            <ul>
                                                <li className="custom-breadcrumb-item active">
                                                    <button>
                                                        <i className="fal fa-home"></i>
                                                    </button>
                                                </li>
                                                <li className="custom-breadcrumb-item">
                                                    <span>System Check</span>
                                                </li>
                                                <li className="custom-breadcrumb-item">
                                                    <span>Student Identification</span>
                                                </li>
                                                <li className="custom-breadcrumb-item">
                                                    <span>Test</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="breadcrumb-btns">
                                            <Link to="/" className="btn btn-primary">
                                                <i className="fal fa-chevron-left"></i> Back
                                            </Link>
                                        </div>
                                    </div>
                                    <h4 className="assessment__name">Details</h4>
                                    <div className="custom__accordion__box">
                                        <div className="accordion__head d-flex align-items-center justify-content-between">
                                            <div className="head__bar">
                                                <i className="fal fa-clock"></i>
                                                <div className="timer">Time Left: <span className="as-text-red">{fancyTimeFormat(time)}</span></div>
                                            </div>
                                            <button type="button" onClick={() => setOpen(!open)} className="btn btn-primary"><i className={open ? "fal fa-chevron-up" : "fal fa-chevron-down"}></i></button>
                                        </div>
                                        <div className={"textseries-content-blk white-brd-blk mb-3 " + (open ? "show" : "")}>
                                            <div className="row camhidden">
                                                <div className="col-md-6">
                                                    <div className="textseries-info-details">
                                                        <ul>
                                                            <li>
                                                                <span className="heading">Type:</span>
                                                                <span className="content">
                                                                    <div className="assessment-01">
                                                                        <p className="as-text-blue">
                                                                            <i className="fal fa-eye"></i>
                                                                            <span className="overflow-ellipsis2">Preview</span>
                                                                        </p>
                                                                    </div>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className="heading">Status:</span>
                                                                <span className="content">
                                                                    <div className="assessment-03">
                                                                        <p className="as-blue-bg as-widget">PREVIEW</p>
                                                                    </div>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className="heading">Condition:</span>
                                                                <span className="content">
                                                                    <div className="assessment-03">
                                                                        <p className="as-blue-bg as-widget">PREVIEW</p>
                                                                    </div>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="textseries-info-details">
                                                        <ul>
                                                            <li>
                                                                <span className="heading">Open Date:</span>
                                                                <span className="content">
                                                                    <div className="assessment-03">
                                                                        <p className="as-blue-bg as-widget">PREVIEW</p>
                                                                    </div>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className="heading">Close Date:</span>
                                                                <span className="content">
                                                                    <div className="assessment-03">
                                                                        <p className="as-blue-bg as-widget">PREVIEW</p>
                                                                    </div>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className="heading">Time Limit:</span>
                                                                <span className="content">
                                                                    <div className="as-text-red">{timeLimit}</div>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-15">
                                                    <div className="textseries-info-details">
                                                        <ul>
                                                            <li>
                                                                <span className="heading">Attempts:</span>
                                                                <span className="content">
                                                                    <div className="assessment-03">
                                                                        <p className="as-blue-bg as-widget">PREVIEW</p>
                                                                    </div>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {!isQuiz
                                        ? <PreviewQuestionsExam setTime={settime} setResult={setResult} setReviewData={setReviewData} trigger={trigger}/>
                                        :
                                        <div className="subject-no-content">
                                            <div className="no-img"></div>
                                            <div className="no-content">
                                                <h4>Data Not Found</h4>
                                            </div>
                                        </div>
                                    }
                                    <div className="textseries-content-blk desc-instruction-blk white-brd-blk mb-15 d-none w-100">
                                        <h4>Detailed description and instructions:</h4>
                                        This is test data
                                    </div>
                                </div>
                            )}
                        </div>
                        {(match.params.password === ADMIN_PASSWORD && !result) && <div className="test-series-main-right-blk">
                            <div className="test-series-steps">
                                <ul>
                                    <li className="test-series-steps-item active done">
                                        <a href="javascript:void(0);">
                                            <span>1</span>
                                            <b>Assessment Details</b>
                                        </a>
                                    </li>
                                    <li className="test-series-steps-item active done">
                                        <a href="javascript:void(0);">
                                            <span>1</span>
                                            <b>System Check</b>
                                        </a>
                                    </li>
                                    <li className="test-series-steps-item active done">
                                        <a href="javascript:void(0);">
                                            <span>1</span>
                                            <b>Identification</b>
                                        </a>
                                    </li>
                                    <li className="test-series-steps-item active done">
                                        <a href="javascript:void(0);">
                                            <span>1</span>
                                            <b>Launch Exam</b>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuizPreview;