import React, { useEffect, useMemo, useState } from 'react'
import SkeletonTicketList from '../../loaders/SkeletonTicketList';
import DataTable from "react-data-table-component";
import Str from '../common/Str';
import { GetChatDocuments, GetChatDocumentsFilters } from '../../services/FeedService';
import UserHover from '../common/UserHover';
import { InitalRender } from '../common/Helper';
import { API_BASE, BASE_URL } from '../../utils/Constants';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { RenderFileType } from '../ticket/StatusRender';
import { download } from '../../utils/FileDownload';

export default function SharedFileList(props) {
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [searchType, setSearchType] = useState([])
    const [searchTypeCheck, setSearchTypeCheck] = useState({})
    const [searchAdmin, setSearchAdmin] = useState([])
    const [searchStudent, setSearchStudent] = useState([])
    const [searchUploadCheck, setSearchUploadCheck] = useState({})
    const [data, setData] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [sortkey, setSortKey] = useState("originalName");
    const [sortOrder, setSortOrder] = useState("DESC");
    const [filterData, setFilterData] = useState({ fileType: [], uploadBy: [] })


    const handlePageChange = (pageNo) => {
        setPage(pageNo);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    const handleSort = (column, sortDirection) => {
        setSortKey(column.selector);
        setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
    };

    useEffect(() => {
        const payload = {
            course_id: props?.subject,
            key: sortkey,
            sort: sortOrder,
            search: search,
            page: page,
            limit: perPage,
            extentsion: searchType,
            student: searchStudent,
            admin: searchAdmin,
        }
        setLoading(true);
        GetChatDocuments(payload).then((res) => {
            if (res.status == 200) {
                const respData = res?.data?.data?.data;
                setData(respData);
                setTotalRows(res.data?.data.total);
            }
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setLoading(false);
        })

    }, [props?.subject, page, perPage, search, sortkey, sortOrder, searchType, searchAdmin, searchStudent])

    useEffect(() => {
        const payload = {
            course_id: props?.subject,
        }

        GetChatDocumentsFilters(payload).then((res) => {
            if (res.status === 200) {
                const respData = res.data;
                setFilterData({
                    ...filterData,
                    fileType: [...respData?.exntsionFilters],
                    uploadBy: [...respData?.userFilters]
                })
            }
        }).catch((err) => {
            console.log(err)
        })

    }, [props?.subject])

    const handleDownloadFiles = (row) => {
        let fileURL = row?.attachment;

        if(!fileURL.includes("chat.myaie.ac")){
            fileURL = API_BASE.replace("/api/v1", "") + "/" + row.attachment.replace("/home/myaie/api/public_html/", "").replace("/home/myaie/public_html/", "").replace("public/", "")
        }
        download(fileURL, row?.originalName)
    }

    const columns = useMemo(() => [
        {
            name: "Name",
            selector: "originalName",
            sortable: true,
            cell: (row) => (row?.originalName ?
                <span
                    onClick={() => { handleDownloadFiles(row) }}
                    className={"as-text-blue bold-text plink overflow-ellipsis curser"}
                >
                    {RenderFileType(row?.extentsion).iconOnly} {row?.originalName}
                </span>

                : "-")
        },
        {
            name: "Uploaded By",
            selector: "user_full_name",
            sortable: true,
            cell: (row) => (
                <>
                    {row?.is_admin ? <div className="user-profile assigned-title-blk">
                        <div className="assigned-title-itm">
                            <span
                                className="name-icon cat-dark-red bgBlue  user-drop-down-img">
                                {row?.admin_profile ? (
                                    <img
                                        src={
                                            BASE_URL +
                                            "/" +
                                            row?.admin_profile.replace("/home/myaie/public_html/", "").replace("public/", "")
                                        }
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.closest('.name-icon.cat-dark-red').append(InitalRender(`${row?.admin_first_name} ${row?.admin_last_name}`));
                                            currentTarget.remove();
                                        }}
                                    />

                                ) : (
                                    InitalRender(`${row?.admin_first_name} ${row?.admin_last_name}`)
                                )}
                                {row?.admin_activity_status == "Offline" ? <span className="portal-status-icon offline-u"></span> : <span className="portal-status-icon online-u"></span>}
                            </span>

                            <span className="overflow-ellipsis as-text-blue">{`${row?.admin_first_name} ${row?.admin_last_name}`}</span>
                        </div>
                        <UserHover
                            directionCls="popup-right"
                            hideEmail={true}
                            user={{
                                photo: row?.admin_profile,
                                fullname: row?.admin_full_name,
                                activity_status: row?.admin_activity_status == 1 ? "online" : "offline"
                            }}
                        />
                    </div>
                        : <div className="user-profile assigned-title-blk">
                            <div className="assigned-title-itm">
                                <span
                                    className="name-icon cat-dark-red bgBlue  user-drop-down-img">
                                    {row?.student_profile ? (
                                        <img
                                            src={
                                                BASE_URL +
                                                "/" +
                                                row?.student_profile.replace("/home/myaie/public_html/", "").replace("public/", "")
                                            }
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.closest('.name-icon.cat-dark-red').append(InitalRender(`${row?.student_first_name} ${row?.student_last_name}`));
                                                currentTarget.remove();
                                            }}
                                        />

                                    ) : (
                                        InitalRender(`${row?.student_first_name} ${row?.student_last_name}`)
                                    )}
                                    {row?.student_activity_status == "Offline" ? <span className="portal-status-icon offline-u"></span> : <span className="portal-status-icon online-u"></span>}
                                </span>

                                <span className="overflow-ellipsis as-text-blue">{`${row?.student_first_name} ${row?.student_last_name}`}</span>
                            </div>
                            <UserHover
                                directionCls="popup-right"
                                hideEmail={true}
                                user={{
                                    photo: row?.student_profile,
                                    fullname: row?.student_full_name,
                                    activity_status: row?.student_activity_status == 1 ? "online" : "offline"
                                }}
                            />
                        </div>}
                </>
            )
        },
        {
            name: "Date",
            selector: "created_at",
            sortable: true,
            cell: (row) => (row?.created_at ?
                <div className="dateTime"><p className="right-space">{moment(row.created_at).format("D MMM 'YY")}</p></div>
                : "-")
        },
        {
            name: "Size",
            selector: "size_in_bytes",
            sortable: true,
            cell: (row) => (row?.size ? row?.size : "-")
        },
        {
            name: "Action",
            cell: (row) => (
                <div className="assessment-08 d-flex">
                    <div className="as-buttons">
                        <button
                            title="Download"
                            className="btn btn-primary rounded-circle"
                            onClick={() => { handleDownloadFiles(row) }}
                        >
                            <i className="fal fa-download"></i>
                        </button>
                    </div>
                    <div className="as-buttons">
                        <button
                            title="Show in Feed"
                            className="btn btn-primary rounded-circle"
                            onClick={() => { handleShowInFeed(row) }}
                        >
                            <i className="fal fa-comment"></i>
                        </button>
                    </div>
                </div>
            ),
        },
    ]);

    const handleShowInFeed = (row) => {
        props.setTab(row?.comment_id, row?.message_id);
    }

    const handleSearchFilter = e => {
        const value = e.target.value
        setSearch(value);
    }

    const resetFilter = () => {
        setSearch("");
        setSearchType([]);
        setSearchTypeCheck({});
        setSearchAdmin([]);
        setSearchStudent([]);
        setSearchUploadCheck({});
    }

    const handleTypeFilter = (e) => {
        const value = e.target.value;
        let arr = [...searchType];
        if (arr.includes(value)) {
            arr.splice(arr.indexOf(value), 1);
        } else {
            arr.push(value);
        }

        setSearchType([...arr]);
        setSearchTypeCheck({
            ...searchTypeCheck,
            value: !searchTypeCheck[value]
        })
    }

    const renderFileTypeFilterUI = () => {

        return (filterData?.fileType.map((type, ind) => (
            <li key={ind}>
                <input
                    type="checkbox"
                    id={`${type.value}-${ind}`}
                    value={type.value}
                    onClick={handleTypeFilter}
                    checked={searchType.includes(type?.value)}
                />
                <label for={`${type.value}-${ind}`}>{RenderFileType(type?.value).html}</label>
            </li>
        )))
    }

    const handleUploadByFilter = (user, key) => {
        const value = user?.value;
        let adminArr = [...searchAdmin];
        let studentArr = [...searchStudent];
        if (user?.is_admin == 1) {
            if (adminArr.includes(value)) {
                adminArr.splice(adminArr.indexOf(value), 1);
            } else {
                adminArr.push(value);
            }
        } else {
            if (studentArr.includes(value)) {
                studentArr.splice(studentArr.indexOf(value), 1);
            } else {
                studentArr.push(value);
            }
        }
        setSearchAdmin([...adminArr]);
        setSearchStudent([...studentArr]);

        setSearchUploadCheck({
            ...searchUploadCheck,
            [key]: !searchUploadCheck[key]
        })
    }

    const renderUploadByFilterUI = () => {
        return filterData?.uploadBy.map((user, ind) => (
            <li key={ind}>
                <input
                    type="checkbox"
                    id={`${user.value}-${user?.is_admin}`}
                    value={user.value}
                    onClick={() => { handleUploadByFilter(user, `${user.value}-${user?.is_admin}`) }}
                    checked={searchUploadCheck[`${user.value}-${user?.is_admin}`]}
                />
                <label for={`${user.value}-${user?.is_admin}`}>{user?.label}</label>
            </li>
        ))
    }

    return (
        <div className="custom-table-div filter-search-icon card card-table-custom">
            <div className='search-filter-div'>
                <div className='search-filter-div-left'>
                    <div className="system-administration-table table-responsive">
                        <div className="table-responsive-div">
                            <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                <div id="assessment-table-main_filter" className="dataTables_filter">
                                    <label for="filterDataTable" className="icon"></label>
                                    <input
                                        type="text"
                                        name="filterDataTable"
                                        className="filter-text"
                                        placeholder="Search"
                                        onChange={handleSearchFilter}
                                        value={search}
                                    />
                                    <div className="filter-eff filter-data-btn">
                                        <button className="filter-buttons">
                                            <i className="fal fa-filter"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>                     
                        </div>
                        <div className="filter-button-group">
                            <div className="assessment-table-filter">
                                <div className="btn-type-filter dropdown-comman">
                                    <div className={`dropdown multiselect`}>
                                        <button
                                            className={`btn btn-default dropdown-toggle ${searchType.length ? 'btn-selected' : ''}`}
                                            type="button"
                                            id="dropdownMenu1"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="true"
                                            title="File Type"
                                        >
                                            <span>
                                                File Type <i className="fal fa-angle-down"></i>
                                            </span>
                                        </button>
                                        <form className="dropdown-menu remove-list-style">
                                            <ul aria-labelledby="dropdownMenu1">
                                                {renderFileTypeFilterUI()}
                                            </ul>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="assessment-table-filter">
                                <div className="btn-type-filter dropdown-comman">
                                    <div className={`dropdown multiselect`}>
                                        <button
                                            className={`btn btn-default dropdown-toggle ${(searchStudent.length || searchAdmin.length) ? 'btn-selected' : ''}`}
                                            type="button"
                                            id="dropdownMenu1"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="true"
                                            title="Uploaded By"
                                        >
                                            <span>
                                                Uploaded By <i className="fal fa-angle-down"></i>
                                            </span>
                                        </button>
                                        <form className="dropdown-menu remove-list-style">
                                            <ul aria-labelledby="dropdownMenu1">
                                                {renderUploadByFilterUI()}
                                            </ul>
                                        </form>
                                    </div>
                                </div>
                            </div> 
                            <div className="reset-btn-group">
                                <div className="button-reset">
                                    <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                                        <i className="fal fa-redo"></i>Reset
                                    </button>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
                <div className='search-filter-div-right'>
                    <div className="add-ticket-blk">
                        <button className="btn btn-white-bordered" title="Back" onClick={() => { props.setTab() }}>
                            <i className="fal fa-chevron-left"></i>Back
                        </button>
                    </div>
                </div>
            </div>                 
            <DataTable
                data={data}
                columns={columns}
                progressPending={loading}
                progressComponent={<SkeletonTicketList />}
                pagination
                paginationServer
                defaultSortField="originalName"
                defaultSortAsc={false}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                highlightOnHover={false}
                onSort={handleSort}
                paginationTotalRows={totalRows}
            />
        </div>
    )
}
