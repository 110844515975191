import React, { useEffect, useState } from "react";
import NotificationAllPastFilterTabs from "./allNotification/NotificationAllPastFilterTabs";
import NotificationAllTodayFilterTabs from "./allNotification/NotificationAllTodayFilterTabs";
import NotificationAllYesterdayFilterTabs from "./allNotification/NotificationAllYesterdayFilterTabs";

import NotificationAllLastWeekFilterTabs from "./allNotification/NotificationAllLastWeekFilterTabs";
import NotificationAllTwoWeekAgoFilterTabs from "./allNotification/NotificationAllTwoWeekAgoFilterTabs";
import NotificationAllThreeWeekAgoFilterTabs from "./allNotification/NotificationAllThreeWeekAgoFilterTabs";
import NotificationAllLastMonthFilterTabs from "./allNotification/NotificationAllLastMonthFilterTabs";

export default function NotificationAllFilterTabs(props) {
  // All notification
  const { AllvisibilityTab, notificationTabDef } = props.functionalParentCompneneLvl1;
  const { activeNotification } = props;

  const handleSelectNotification = (e, notificationId, readNotificationId) => {
    props.handleSelectNotification(e, notificationId, readNotificationId);
  };

  return (
    <>
      <div className={"tab-pane " + (notificationTabDef == 'all' ? 'show active' : 'fade')} id="all">
        { AllvisibilityTab && AllvisibilityTab.totalCount ? (
          <>
            {AllvisibilityTab &&
            AllvisibilityTab.todayNotification &&
            AllvisibilityTab.todayNotification.total > 0 ? (
              <NotificationAllTodayFilterTabs
                notificationTabDef={notificationTabDef}
                functionalParentCompneneLvl2={props}
                handleSelectNotification={handleSelectNotification}
                defaultTab={AllvisibilityTab.defaultTodayTab}
              />
            ) : null}

            {AllvisibilityTab &&
            AllvisibilityTab.yesterdayNotification &&
            AllvisibilityTab.yesterdayNotification.total > 0 ? (
              <NotificationAllYesterdayFilterTabs
                notificationTabDef={notificationTabDef}
                functionalParentCompneneLvl2={props}
                handleSelectNotification={handleSelectNotification}
                defaultTab={AllvisibilityTab.defaultYesterdayTab}
              />
            ) : null}

            {AllvisibilityTab &&
            AllvisibilityTab.last_weekNotification &&
            AllvisibilityTab.last_weekNotification.total > 0 ? (
              <NotificationAllLastWeekFilterTabs
                notificationTabDef={notificationTabDef}
                functionalParentCompneneLvl2={props}
                handleSelectNotification={handleSelectNotification}
                defaultTab={AllvisibilityTab.defaultLastWeekTab}
              />
            ) : null}

            {AllvisibilityTab &&
            AllvisibilityTab.twoWeekNotification &&
            AllvisibilityTab.twoWeekNotification.total > 0 ? (
              <NotificationAllTwoWeekAgoFilterTabs
                notificationTabDef={notificationTabDef}
                functionalParentCompneneLvl2={props}
                handleSelectNotification={handleSelectNotification}
                defaultTab={AllvisibilityTab.defaultTwoWeekAgoTab}
              />
            ) : null}

            {AllvisibilityTab &&
            AllvisibilityTab.threeWeeNotification &&
            AllvisibilityTab.threeWeeNotification.total > 0 ? (
              <NotificationAllThreeWeekAgoFilterTabs
                notificationTabDef={notificationTabDef}
                functionalParentCompneneLvl2={props}
                handleSelectNotification={handleSelectNotification}
                defaultTab={AllvisibilityTab.defaultThreeWeekAgoTab}
              />
            ) : null}

            {AllvisibilityTab &&
            AllvisibilityTab.LastMonthNotification &&
            AllvisibilityTab.LastMonthNotification.total > 0 ? (
              <NotificationAllLastMonthFilterTabs
                notificationTabDef={notificationTabDef}
                functionalParentCompneneLvl2={props}
                handleSelectNotification={handleSelectNotification}
                defaultTab={AllvisibilityTab.defaultLastMonthTab}
              />
            ) : null}

            {AllvisibilityTab &&
            AllvisibilityTab.pastNotification &&
            AllvisibilityTab.pastNotification.total > 0 ? (
              <NotificationAllPastFilterTabs
                notificationTabDef={notificationTabDef}
                functionalParentCompneneLvl2={props}
                handleSelectNotification={handleSelectNotification}
                defaultTab={AllvisibilityTab.defaultPastTab}
              />
            ) : null}
          </>
        ) :
          <>
            <div className="no-notification">
              No notifications available.
            </div>
          </>
        }
      </div>
    </>
  );
}
