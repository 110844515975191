import React, { useState, useEffect, createRef } from "react";
import _ from "underscore";
import "../../assets/css/comment.css";
import { InitalRender, originalValues, rollsWidget } from "./../common/Helper";
import HtmlInputEditorShow from "../common/HtmlInputEditorShow";
import {
  EditorState,
  convertFromRaw,
  convertToRaw,
  ContentState,
  Modifier,
  Entity,
  CompositeDecorator,
} from "draft-js";
import HtmlInputEditorShowComment from "../common/HtmlInputEditorShowComment";
import { getRandomInt } from "../../utils/commonFunction";
import Swal from "sweetalert2";
import { FilePond, File, registerPlugin, setOptions } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { useSelector } from "react-redux";
import UserHover from "../common/UserHover";
import moment from "moment";
import Str from "../common/Str";
import ThumbsUpIcon from '../../assets/images/Thumbsup.png'
import ThumbsDownIcon from '../../assets/images/ThumbsDown.png'
import RedHeartIcon from '../../assets/images/RedHeart.png'
import PoutingIcon from '../../assets/images/Pouting.png'
import OpenFaceIcon from '../../assets/images/OpenFace.png'
import GrinningIcon from '../../assets/images/GrinningFace.png'
import FrowningIcon from '../../assets/images/Frowning.png'
import UnknownStudent from "../../assets/images/ic_unknown_user.png"
import UnknownStudentStatus from "../../assets/images/ic_unknown_user_online_status.png"
import HtmlParser from "react-html-parser";
import { readCommentMessages } from "../../services/QualificationService";


const FeedComments = (props) => {
  const [showReply, setShowReply] = useState(false);
  const [data, setData] = useState([]);
  const [copyText, setCopyText] = useState(0);
  const [resStr, setResStr] = useState("");
  const [newStr, setNewStr] = useState("");
  const [unreadMsg, setUnreadMsg] = useState("");
  const [globalMention, setGlobalMention] = useState(false);
  const [globalImportant, setGlobalImportant] = useState(false);
  const [globalAttachment, setGlobalAttachment] = useState(false);
  const [globalMentionComment, setGlobalMentionComment] = useState(false);
  const [globalImportantComment, setGlobalImportantComment] = useState(false);
  const [globalAttachmentComment, setGlobalAttachmentComment] = useState(false);
  const [actionState, setActionState] = useState(0);
  const [editableArr, setEditableArr] = useState([]);
  const [importantArr, setImportantArr] = useState([{}]);
  const [editableAllArr, setEditableAllArr] = useState([]);
  const [users, setUsers] = useState(props.userData);
  const [newComments, setNewComments] = useState(0);
  const [reduceReq, setReduceReq] = useState(true);
  const [showNewTag, setShowNewTag] = useState(false);
  const [team, setTeam] = useState([]);
  const [userRole, setUserRole] = useState("");

  const user = useSelector((state) => state.user);

  const HandleSpan = (props) => {
    return (
      <span style={styles.handle} data-offset-key={props.offsetKey}>
        {props.children}
      </span>
    );
  };

  

  function handleStrategy(contentBlock, callback, contentState) {
    findWithRegex(HANDLE_REGEX, contentBlock, callback);
  }

  const compositeDecorator = new CompositeDecorator([
    { strategy: handleStrategy, component: HandleSpan },
  ]);
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty(compositeDecorator)
  );

  useEffect(() => {
    globalIconHandle(data);
  }, [data]);

  // useEffect(() => {
  //   if(hoverId === "") return;
  //   GetMentionedUserDetails(hoverId).then(data => {
  //     console.clear();
  //     console.log("dataaaaaaaaaa -=-> ", data);
  //   }).catch(error => {
  //     console.log("errorrrrrrr===> ", error);
  //   })
  // }, [hoverId])

  // console.log("hoverId -=-=-=-=-> ", hoverId);

  // useEffect(() => {
  //   let mounted = true
  //   GetSupportTeamService({ sid: props.subjectId })
  //     .then((res) => {
  //       if (mounted) {
  //         setTeam(res.data.team);
  //         if(res.data.team) {
  //           let tempObj = Object.keys(res.data.team).map(item => {
  //             return {
  //               ...res.data.team[item], role:item
  //             }
  //           });
  //         let adminData = props.comments.map((itemArr)=>{
  //           let finalobj= tempObj.find(item => {
  //              return item.id == itemArr.admin_id
  //         })  
  //         if (itemArr.admin_id == null) {
  //           finalobj = {role : "student"}
  //         }
  //         else if (!finalobj) {
  //           finalobj = {role : itemArr.adminuser.job_title}
  //         }
  //         return {...itemArr, roleKey: finalobj.role}
  //         })
  //         setData(shuffleArray(adminData));
  //         }
  //       }
  //     })
  //     .catch((error) => { });

  //   return function cleanup() {
  //     mounted = false
  //   }
  // }, [props.comments]);


  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const HANDLE_REGEX = /\@[\w]+/g;
  const styles = {
    editor: {
      border: "1px solid #ddd",
      cursor: "text",
      fontSize: 16,
      minHeight: "40px",
      padding: 10,
      background: "#000",
    },
    handle: {
      color: "rgba(98, 177, 254, 1.0)",
      direction: "ltr",
      unicodeBidi: "bidi-override",
    },
  };

  function findWithRegex(regex, contentBlock, callback) {
    const text = contentBlock.getText();
    let matchArr, start;
    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index;
      callback(start, start + matchArr[0].length);
    }
  }

  const copyEmailText = (text) => {
    setCopyText(1);
    navigator.clipboard.writeText(text);
    setTimeout(() => {
      setCopyText(0);
    }, 1000);
  };

  useEffect(() => {
    props.childFun.current = readComment;
  });

  useEffect(() => {
    // console.log("props.comments --=-=-=--=-=-=-> ", props.comments);
    setData(shuffleArray(props.comments));
    let finalStr = "";
    let usersArr = [];
    // let isUnreadMsg = false
    let arrLen = props.comments.length;
    let newCount = 0;
    let allNewCount = 0;
    if (props.comments) {
      // console.log("propssssssssssssssss----> ", props.comments);
      props.comments.map((i) => {
        usersArr.push(
          i && i.user && i.user.fullname ? i.user.fullname : " "
        );
        // if ((!props?.readMessages?.comment_id) || (i.id > props?.readMessages?.comment_id)) {
        //   isUnreadMsg = true
        // }
        if (arrLen == 1 && !props?.readMessages?.comment_id) {
          readComment(i.message_id, i.id);
        }
        if (props?.readMessages?.comment_id < i.id) {
          if (i.user_id !== user.id) {
            newCount += 1;
          }
          allNewCount += 1;
          setShowNewTag(true);
        }
        // setNewComments(newCount > 0 ? newCount : 0);
        setNewComments(newCount > 0 ? newCount - 1 : 0);
      });
    }

    var set = new Set(usersArr);
    if (set.size == 1) {
      finalStr =
        usersArr.length -
        1 +
        (usersArr.length === 1 ? " reply" : " replies") +
        " from " +
        usersArr[0];
    } else if (set.size == 2) {
      let revisedArr = Array.from(set);
      let tempUsers = revisedArr[0] + " and " + revisedArr[1];
      finalStr = usersArr.length - 1 + " replies from " + tempUsers;
    } else if (set.size > 2) {
      let revisedArr = Array.from(set);
      let aboveTwo = revisedArr.length - 2;
      let tempUsers = revisedArr[0];
      finalStr =
        usersArr.length -
        1 +
        " replies from " +
        tempUsers +
        " + " +
        " others ";
    }
    setResStr(
      usersArr.length >
        //  - newCount
        0
        ? finalStr
        : ""
    );
    setUnreadMsg(newCount > 1 ? true : false);
    if (
      (newCount == 1 && props.comments.length > 0) ||
      (allNewCount > 0 &&
        props.comments[props.comments.length - 1].user_id == user.id)
    ) {
      readComment(
        props.comments[props.comments.length - 1].message_id,
        props.comments[props.comments.length - 1].id
      );
    }
  }, [props.comments, actionState]);

  useEffect(()=>{
    let mounted = true
    if(props.teamObj) {
    let adminData = props.comments.map((itemArr)=>{
      let finalobj= props.teamObj.find(item => {
         return item.id == itemArr.admin_id
    })  
    // console.log(finalobj,"finalobj")
    if (itemArr.admin_id == null) {
      finalobj = {role : "student"}
    }
    else if (!finalobj) {
      finalobj = {role : itemArr?.adminuser?.job_title}
    }
    return {...itemArr, roleKey: finalobj.role}
    })
    setData(shuffleArray(adminData));
  }

    return function cleanup() {
          mounted = false
       }
  },[props.comments,actionState])

  const shuffleArray = (arr) => {
    arr.map((e, i) => {
      if (!e.user) arr[i].user = e.adminuser;
    });
    return arr;
  };

  const readComment = (msg, comment) => {
    readCommentMessages({ message_id: msg, comment_id: comment }).then(
      (data) => {
        console.log("the data of read response is ", data);
      }
    );
  };

  const expandComList = () => {
    let allData = data;
    let isUnreadMsg = false;
    let reduceCount = 0;
    allData.map((i) => {
      if (
        !props?.readMessages?.comment_id ||
        i.id > props?.readMessages?.comment_id
      ) {
        isUnreadMsg = true;
        reduceCount += 1;
      }
    });
    if (isUnreadMsg) {
      let msg_id = allData[0].message_id;
      let comment_id = allData[allData.length - 1].id;
      // props.updateReadMessages(msg_id, comment_id)
      readComment(msg_id, comment_id);
      if (reduceReq) {
        props.reduceCount(reduceCount, props.room_id);
        setReduceReq(false);
      }
      setActionState(getRandomInt(5000));
    }
  };

  const handleBtnMessage = (
    currentData,
    comment_user,
    login_user,
    important,
    item,
    user
  ) => {
    // let isBlock = isJsonString(currentData)
    // if (isBlock) {
    //   var blocksFromHTML = JSON.parse(currentData);
    //   // setEditorState(EditorState.createWithContent(blocksFromHTML));
    //   const entityMap = blocksFromHTML.entityMap;
    //   const mentions = [];
    //   Object.values(entityMap).forEach(entity => {
    //     if (entity.type === 'MENTION') {
    //       mentions.push(entity.data.text);
    //     }
    //   });
    //   if (mentions.length > 0) {
    //     if (important) {
    //       return 'important-tag'
    //     }
    //     else if (mentions.includes(`@${user.fullname}`)) {
    //       return 'my-tag-reply'
    //     }
    //     else if (comment_user == login_user) {
    //       return 'other-reply'
    //     }
    //     else {
    //       return ''
    //     }
    //   }
    //   else {
    //     if (comment_user == login_user) {
    //       return 'other-reply'
    //     }
    //     else {
    //       return ''
    //     }
    //   }
    // }
    // return ''

    // if (currentData.includes(`@${user.fullname}`)) {
    if (important) {
      return "important-tag";
    } else if (currentData.includes(`@${user.fullname}`)) {
      return "my-tag-reply";
    } else if (comment_user == login_user) {
      return "other-reply";
    } else {
      return "";
    }
    // }
    // else {
    //   if (comment_user == login_user) {
    //     return 'other-reply'
    //   }
    //   else {
    //     return ''
    //   }
    // }
  };

  const userTagColor = (comment_user, login_user) => {
    if (comment_user == login_user) {
      return " my-tag ";
    } else {
      return " other-tag ";
    }
  };

  const showTagIcon = (
    currentData,
    comment_user,
    login_user,
    item,
    commentData,
    index
  ) => {
    // let isBlock = isJsonString(currentData)
    // if (isBlock) {
    //   var blocksFromHTML = JSON.parse(currentData);
    //   const entityMap = blocksFromHTML.entityMap;
    //   const mentions = [];
    //   Object.values(entityMap).forEach(entity => {
    //     if (entity.type === 'MENTION') {
    //       mentions.push(entity.data.text);
    //     }
    //   });

    //   if ((mentions.length > 0) && (mentions.includes(`@${item.fullname}`))) {
    //     if (!globalMention) {
    //       if (props?.readMessages?.comment_id >= commentData.id) {
    //         setGlobalMention(true)
    //       }
    //     }
    //     if (!globalMentionComment) {
    //       if (props?.readMessages?.comment_id < commentData.id) {
    //         setGlobalMentionComment(true)
    //       }
    //     }
    //     return ' post-reaction-icon '
    //   }
    //   else {
    //     return ''
    //   }
    // }
    if (currentData.includes(`@${item.fullname}`)) {
      if (index < data.length - 1) {
        if (!globalMention) {
          setGlobalMention(true);
        }
      } else {
        if (!globalMentionComment) {
          setGlobalMentionComment(true);
        }
      }
      return " post-reaction-icon ";
    } else {
      return "";
    }
  };

  const isLiked = (login_user, likeArr) => {
    if (likeArr.length) {
      for (var i = 0; i < likeArr.length; i++) {
        if (likeArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };

  const isDisliked = (login_user, likeArr) => {
    if (likeArr.length) {
      for (var i = 0; i < likeArr.length; i++) {
        if (likeArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };

  const isLoved = (login_user, loveArr) => {
    if (loveArr.length) {
      for (var i = 0; i < loveArr.length; i++) {
        if (loveArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };
  const isLaughed = (login_user, laughArr) => {
    if (laughArr.length) {
      for (var i = 0; i < laughArr.length; i++) {
        if (laughArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };
  const isSurprised = (login_user, surpriseArr) => {
    if (surpriseArr.length) {
      for (var i = 0; i < surpriseArr.length; i++) {
        if (surpriseArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };

  const isSad = (login_user, sadArr) => {
    if (sadArr.length) {
      for (var i = 0; i < sadArr.length; i++) {
        if (sadArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };

  const isAngry = (login_user, angryArr) => {
    if (angryArr.length) {
      for (var i = 0; i < angryArr.length; i++) {
        if (angryArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };

  const renderActionList = (usersArr = [], position) => {
    // console.log("details -=-=-=-=-> ", deatils);
    // console.log("usersArrv ----> ", usersArr);
    return usersArr.map((i, index) => {
      if (!i.user) {
        usersArr[index].user = i.adminuser;
        usersArr[index].user_id = i?.adminuser?.UserID;
        usersArr[index].userType = "admin";
      }
      return (
        <li key={index}>
          <div className="user-drop-down-img online-status card-left assigned-title-blk">
            {i?.user ? 
            <>
              {i?.user?.photo == null ? (
                i?.user?.fullname ? InitalRender(i?.user?.fullname) : ""
              ) : (
                <img src={i?.user?.photo} />
              )}
              <span
                className={`portal-status-icon ${
                  i.user?.activity_status ? (i.user?.activity_status?.toLowerCase() == "online"
                    ? "online-u"
                    : i.user?.activity_status?.toLowerCase() == "donotdisturb"
                    ? "donotdisturb-u"
                    : "offline-u") : "offline-u"
                }`}
              >
                {" "}
              </span>
            </>
            :<>
            <img src={UnknownStudent} alt="unknown" />
            <span className="portal-status-icon border-0">
              <img src={UnknownStudentStatus} alt="unknown" />    
            </span>
            </>}

            <UserHover
              directionCls={"popup-top"}
              user={i.user}
              hideEmail={true}
              online={`portal-status-icon ${
                i.user?.activity_status?.toLowerCase() == "online"
                  ? "online-u"
                  : i.user?.activity_status?.toLowerCase() == "donotdisturb"
                  ? "donotdisturb-u"
                  : "offline-u"
              }`}
            />
            {/* <div className="assigned-title-info">
              <article>
                <figure className="bgBlue">
                  <div className="user-drop-down-img">
                    {i.user.photo ?
                      // <img src={i.user.photo || null} alt={i.user.fullname || null} /> 
                      <img src={i.user.photo} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.closest('.bgBlue').append(InitalRender(originalValues(i.user.fullname, 23)));
                        currentTarget.remove();
                      }} />
                      : InitalRender(originalValues(
                        i.user.fullname
                      ))}
                    <span className={`portal-status-icon ${i.user?.activity_status?.toLowerCase() == 'online' ? 'online-u' : i.user?.activity_status?.toLowerCase() == 'donotdisturb' ? 'donotdisturb-u' : 'offline-u'}`}
                    ></span>
                  </div>
                </figure>
                <figcaption>
                  <h4>{originalValues(i.user.fullname)}</h4>
                  <p>
                    {i.user.email && <i className="fal fa-envelope"></i>}
                    <a className="hover-color-blue" onClick={() => copyEmailText(i.user.email)}>{i.user.email}</a>
                    {copyText ? (<i className="fas fa-check-circle cat-greenhex"></i>) : (i.user.email && <i className="fal fa-copy hover-color-blue" onClick={() => copyEmailText(i.user.email)} ></i>)}
                  </p>
                </figcaption>
              </article>
            </div> */}
          </div>
          <div className="info-drop">
            <span title={i?.user?.first_name ? i?.user?.first_name : "Unknown User"}>{i?.user?.first_name ? i?.user?.first_name : "Unknown User"}</span>
            {i?.user ? 
            <a
              href="#!"
              title={`${
                isLiked(
                  i.user.id,
                  data[position] && data[position].likes
                    ? data[position].likes
                    : []
                )
                  ? "Like"
                  : isDisliked(
                      i.user.id,
                      data[position].dislikes ? data[position].dislikes : []
                    )
                  ? "Dislike"
                  : isLoved(
                      i.user.id,
                      data[position].love ? data[position].love : []
                    )
                  ? "Love"
                  : isLaughed(
                      i.user.id,
                      data[position].laugh ? data[position].laugh : []
                    )
                  ? "Laugh"
                  : isSurprised(
                      i.user.id,
                      data[position].surprised ? data[position].surprised : []
                    )
                  ? "Surprised"
                  : isSad(
                      i.user.id,
                      data[position].sad ? data[position].sad : []
                    )
                  ? "Sad"
                  : isAngry(
                      i.user.id,
                      data[position].angry ? data[position].angry : []
                    )
                  ? "Angry"
                  : "NA"
              }`}
            >              
              {
                  isLiked(i.user.id, data[position] && data[position].likes ? data[position].likes : [])
                    ? <img className="chat-reaction-icon" src={ThumbsUpIcon} alt="Like" />
                    : isDisliked(
                        i.user.id,
                        data[position].dislikes ? data[position].dislikes : []
                      )
                    ? <img className="chat-reaction-icon" src={ThumbsDownIcon} alt="Dislike" />
                    : isLoved(i.user.id, data[position].love ? data[position].love : [])
                    ? <img className="chat-reaction-icon" src={RedHeartIcon} alt="Love" />
                    : isLaughed(
                        i.user.id,
                        data[position].laugh ? data[position].laugh : []
                      )
                    ? <img className="chat-reaction-icon" src={GrinningIcon} alt="Laugh" />
                    : isSurprised(
                        i.user.id,
                        data[position].surprised ? data[position].surprised : []
                      )
                    ? <img className="chat-reaction-icon" src={OpenFaceIcon} alt="Surprised" />
                    : isSad(i.user.id, data[position].sad ? data[position].sad : [])
                    ? <img className="chat-reaction-icon" src={FrowningIcon} alt="Sad" />
                    : isAngry(
                        i.user.id,
                        data[position].angry ? data[position].angry : []
                      )
                    ? <img className="chat-reaction-icon" src={PoutingIcon} alt="Angry" />
                    : null
                }
            </a>
                 : null}
          </div>
        </li>
      );
    });
  };

  const deleteConfirm = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete comment!",
    }).then((result) => {
      if (result.isConfirmed) {
        props.deleteHandle({
          comment_id: id,
          message_type: "deleted",
          message_id: null,
          table_type: "comment",
          deletedFiles: [],
        });
        // setActionState(getRandomInt(5000))
      }
    });
  };

  const editComment = (item) => {
    // console.log('the editable comment is ', item)
    setTimeout(() => {
      props.setShowHideEdit([item.id]);
    }, 200);
    // setEditableArr([item.id])
    // setEditableAllArr((pre) => [...pre, item])
  };

  const sendClickHandle = (
    messageValue,
    comment_id,
    attachments,
    sizes,
    message_id
  ) => {
    readComment(
      props.comments[props.comments.length - 1].message_id,
      props.comments[props.comments.length - 1].id
    );
    let tempImportant = false;
    let tempData = data;
    let deletedFiles = [];
    let remainFiles = [];
    data.map((i) => {
      if (i.id == comment_id) {
        i.attachments.map((j) => {
          if (j.isDeleted) {
            deletedFiles.push(j.attachment);
          } else {
            remainFiles.push(j);
          }
        });
        tempImportant = i.important;
        attachments = [...remainFiles.map((x) => x.attachment), ...attachments];
        deletedFiles = deletedFiles;
        sizes = [...remainFiles.map((x) => (x.size ? x.size : "NA")), ...sizes];
      }
    });

    props.editHandle(
      messageValue,
      comment_id,
      tempImportant,
      attachments,
      sizes,
      deletedFiles,
      message_id
    );

    let tempArr = [];

    // editableArr.map(i => {
    //   if (i != comment_id) {
    //     tempArr.push(i)
    //   }
    // })
    // setEditableArr(tempArr)
    props.setShowHideEdit([]);
    // setActionState(getRandomInt(5000))
  };

  const importantHandle = (commentData, value) => {
    let tempArr = importantArr[0];
    tempArr[commentData.id] = value;
    setImportantArr((pre) => [tempArr]);

    let tempData = data;
    tempData.map((i) => {
      if (i.id == commentData.id) {
        i.important = value;
      }
    });
    globalIconHandle(tempData);

    setData(tempData);
  };

  const deletedFileHandle = (id, path) => {
    let tempData = data;
    tempData.map((i) => {
      if (i.id == id) {
        i.attachments.map((j) => {
          if (path != j.attachment) {
            if (!j.isDeleted) {
              j.isDeleted = false;
            }
          } else {
            j.isDeleted = true;
          }
        });
      }
    });
    setData((pre) => [...tempData]);
  };

  const globalIconHandle = (tempData) => {
    // console.log('tempData is here now ', tempData)
    let tempImp = false;
    let tempAttach = false;
    let tempImpComment = false;
    let tempAttachComment = false;

    tempData.map((item, index) => {
      // if (item.important) {
      //   if (!tempImp) {
      //     tempImp = true
      //   }
      // }
      // if (item.attachments && item.attachments.length) {
      //   if (!tempAttach) {
      //     tempAttach = true
      //   }
      // }

      // if (item.important) {
      //   if (!tempImp) {
      //     if (props?.readMessages?.comment_id >= item.id) {
      //       tempImp = true
      //     }
      //   }
      //   if (!tempImpComment) {
      //     if (props?.readMessages?.comment_id < item.id) {
      //       tempImpComment = true
      //     }
      //   }
      // }

      // if (item.attachments && item.attachments.length) {
      //   if (!tempAttach) {
      //     if (props?.readMessages?.comment_id >= item.id) {
      //       tempAttach = true
      //     }
      //   }
      //   if (!tempAttachComment) {
      //     if (props?.readMessages?.comment_id < item.id) {
      //       tempAttachComment = true
      //     }
      //   }
      // }

      if (index < tempData.length - 1) {
        if (item.important) {
          if (!tempImp) {
            tempImp = true;
          }
        }
        if (item.attachments && item.attachments.length) {
          if (!tempAttach) {
            tempAttach = true;
          }
        }
      } else {
        if (item.important) {
          if (!tempImpComment) {
            tempImpComment = true;
          }
        }
        if (item.attachments && item.attachments.length) {
          if (!tempAttachComment) {
            tempAttachComment = true;
          }
        }
      }

      // let isBlock = isJsonString(item.comment)
      // if (isBlock) {
      //   var blocksFromHTML = JSON.parse(item.comment);
      //   const entityMap = blocksFromHTML.entityMap;
      //   const mentions = [];
      //   let mentionClass = ''
      //   Object.values(entityMap).forEach(entity => {
      //     if (entity.type === 'MENTION') {
      //       mentions.push(entity.data.text);
      //     }
      //   });

      //   if ((mentions.length > 0) && (mentions.includes(`@${props.user.fullname}`))) {
      //     if (!mentionClass) {
      //       mentionClass = true
      //     }
      //     return ' post-reaction-icon '
      //   }
      //   else {
      //     return ''
      //   }
      // }
    });
    setGlobalImportant(tempImp);
    setGlobalAttachment(tempAttach);
    setGlobalImportantComment(tempImpComment);
    setGlobalAttachmentComment(tempAttachComment);
  };

  const handleReset = (item) => {
    if (!props.showHideEditArr.includes(item.id)) {
      props.resetEditor(true);
    }
  };

  const handleReplyToComment = (msg) =>{
    props.showReplyEditor(msg);
  }

  return (
    <>
      <div
        className={
          "other-reply summary-main " +          
          (props.comments.length == 1 ? " hide-comment-cnt " : "")
        }
      >
        <div
          className="comment-con"
          onClick={() => {
            setShowReply(!showReply);
            expandComList();
          }}
        >
          <i
            className={
              "fal " +
              (showReply ? "fa-angle-double-up" : "fa-angle-double-down")
            }
          ></i>
          {showReply ? (
            "Collapse all"
          ) : (
            <div
              className={`comment-con ${
                newComments > 0 ? " new-reply-font" : ""
              }`}
            >
              {newComments > 0 ? <p className="new-cmt-txt"> New</p> : null}{" "}
              {resStr}
            </div>
          )}
        </div>
        {!showReply &&
        (globalImportant || globalMention || globalAttachment) ? (
          <div className="row_icons_fas ls_box">
            {globalImportant ? (
              <i
                className="fal fa-exclamation important-icon"
                title="Important"
              ></i>
            ) : null}
            {globalMention ? (
              <div className=" post-reaction-icon " title="Tag">
                <i className="fal fa-at"></i>
              </div>
            ) : null}
            {globalAttachment ? (
              <i
                className="fal fa-paperclip attachment-icon"
                title="Attachment"
              ></i>
            ) : null}
          </div>
        ) : null}
      </div>
      {data.map(function (item, index) {
        if (index < data.length - 1) {
          if (item.important) {
            if (!globalImportant) {
              if (props?.readMessages?.comment_id >= item.id) {
                setGlobalImportant(true);
              }
            }
          }
          if (item.attachments && item.attachments.length) {
            if (!globalAttachment) {
              if (props?.readMessages?.comment_id >= item.id) {
                setGlobalAttachment(true);
              }
            }
          }
        } else {
          if (item.important) {
            if (!globalImportantComment) {
              if (props?.readMessages?.comment_id >= item.id) {
                setGlobalImportantComment(true);
              }
            }
          }
          if (item.attachments && item.attachments.length) {
            if (!globalAttachmentComment) {
              if (props?.readMessages?.comment_id >= item.id) {
                setGlobalAttachmentComment(true);
              }
            }
          }
        }
        const replyMessage = data.find(msg => msg.id == item.reply_to);                

        return (
          <div
            className={"comment-box-block " + 
              (index < props.comments.length - 1 && !showReply
                ? " hide-reply"
                : " show-reply likeUnlikeBox")
            }
            key={index}
            onClick={() => {
              handleReset(item);
            }}
          >
            {item.reply_to > 0 ? 
            <a href={`#comment-message-${item.reply_to}`} onClick={()=>{setShowReply(true);expandComList();}}>
              <div className="reply-to-card reply-show-card">
                <div className="reply-to-header">
                  <span className="reply-to-head-l">
                    <p className="reply-to-user">{replyMessage?.user && replyMessage?.user?.fullname ? replyMessage?.user?.fullname : "Unknown User"}</p>
                    <span>{moment(replyMessage.created_at,"Do MMMM YYYY, h:mm:ss a").format("dddd, " + Str.TABLE_DATE_TIME_FORMAT)}</span>
                  </span>
                  <span className="reply-to-head-r">
                    {rollsWidget(replyMessage?.roleKey)}                      
                  </span>
                </div>
                <div className="reply-to-content">                    
                  {HtmlParser(replyMessage?.comment)}
                </div>
              </div>
            </a>
             : null}
            <div
              className={
                "card-reply  card-reply-box ps-by-card-header " +
                (item.user_id === props.user.id ? " mycomment" : "")
                + (props?.readMessages?.comment_id && item?.user?.id !== user.id && item.id > props?.readMessages?.comment_id ? 
                  " new-feed-comment-highlight" : "")
              }
              id={`comment-message-${item.id}`}
            >
              <div className="row_icons_fas ls_box desk-post-static">
                {item.important ? (
                  <i
                    className="fal fa-exclamation important-icon"
                    title="Important"
                  ></i>
                ) : null}
                {showTagIcon(
                  item.comment,
                  item.user_id,
                  props.user.id,
                  props.user,
                  item,
                  index
                ) != "" ? (
                  <div
                    className={showTagIcon(
                      item.comment,
                      item.user_id,
                      props.user.id,
                      props.user,
                      item,
                      index
                    )}
                  >
                    <i className="fal fa-at" title="Tag"></i>
                  </div>
                ) : null}

                {item.attachments && item.attachments.length ? (
                  <i
                    className="fal fa-paperclip attachment-icon"
                    title="Attachment"
                  ></i>
                ) : null}
              </div>
              <div className="post-reat-new">
                <div className="post-reat-head">
                  <div className="post-reat-icon">
                    <button
                      className="feed-reaction-button"
                      title="Like"
                      onClick={() => {
                        props.likeDislikeHandle({
                          comment_id: item.id,
                          table_type: "comment",
                          comment_type: 1,
                          message_id: item.message_id,
                        });
                      }}
                    >
                      <img className="chat-reaction-icon" src={ThumbsUpIcon} alt="Like" />
                    </button>
                    <button
                      className="feed-reaction-button"
                      title="Dislike"
                      onClick={() => {
                        // !isDisliked(props.user.id, data[index].dislikes ? data[index].dislikes : []) &&
                        props.likeDislikeHandle({
                          comment_id: item.id,
                          table_type: "comment",
                          comment_type: 2,
                          message_id: item.message_id,
                        });
                      }}
                    >
                      <img className="chat-reaction-icon" src={ThumbsDownIcon} alt="Dislike" />
                    </button>
                    <button
                      className="feed-reaction-button"
                      title="Love"
                      onClick={() => {
                        // !isDisliked(props.user.id, data[index].dislikes ? data[index].dislikes : []) &&
                        props.likeDislikeHandle({
                          comment_id: item.id,
                          table_type: "comment",
                          comment_type: 3,
                          message_id: item.message_id,
                        });
                      }}
                    >
                      <img className="chat-reaction-icon" src={RedHeartIcon} alt="Love" />
                    </button>
                    <button
                      className="feed-reaction-button"
                      title="Laugh"
                      onClick={() => {
                        // !isDisliked(props.user.id, data[index].dislikes ? data[index].dislikes : []) &&
                        props.likeDislikeHandle({
                          comment_id: item.id,
                          table_type: "comment",
                          comment_type: 4,
                          message_id: item.message_id,
                        });
                      }}
                    >
                      <img className="chat-reaction-icon" src={GrinningIcon} alt="Laugh" />
                    </button>
                    <button
                      className="feed-reaction-button"
                      title="Surprised"
                      onClick={() => {
                        // !isDisliked(props.user.id, data[index].dislikes ? data[index].dislikes : []) &&
                        props.likeDislikeHandle({
                          comment_id: item.id,
                          table_type: "comment",
                          comment_type: 5,
                          message_id: item.message_id,
                        });
                      }}
                    >
                      <img className="chat-reaction-icon" src={OpenFaceIcon} alt="Surprised" />
                    </button>
                    <button
                      className="feed-reaction-button"
                      title="Sad"
                      onClick={() => {
                        // !isDisliked(props.user.id, data[index].dislikes ? data[index].dislikes : []) &&
                        props.likeDislikeHandle({
                          comment_id: item.id,
                          table_type: "comment",
                          comment_type: 6,
                          message_id: item.message_id,
                        });
                      }}
                    >
                      <img className="chat-reaction-icon" src={FrowningIcon} alt="Sad" />
                    </button>
                    <button
                      className="feed-reaction-button"
                      title="Angry"
                      onClick={() => {
                        // !isDisliked(props.user.id, data[index].dislikes ? data[index].dislikes : []) &&
                        props.likeDislikeHandle({
                          comment_id: item.id,
                          table_type: "comment",
                          comment_type: 7,
                          message_id: item.message_id,
                        });
                      }}
                    >
                      <img className="chat-reaction-icon" src={PoutingIcon} alt="Angry" />
                    </button>
                  </div>
                  <div className="post-reat-action">
                    <a
                      className="feed-reaction-button"
                      onClick={() => handleReplyToComment(item)}
                      title="Reply"
                      href={`#reply-input-editor-${props?.message_id}`}
                    >
                      <i className="far fa-reply-all"></i>
                    </a>
                    {props?.user?.id == item?.user?.id ? (
                      <>
                        <button
                          className="feed-reaction-button"
                          onClick={() => editComment(item)}
                          title="Edit"
                        >
                          <i className="far fa-edit"></i>
                        </button>
                        <button
                          className="feed-reaction-button"
                          onClick={() => deleteConfirm(item.id)}
                          title="Delete"
                        >
                          <i className="far fa-trash-alt"></i>                        
                        </button>
                      </>
                    ) : null}
                  </div>
                  <div className="post-reat-more">
                    <a href="#!" title="More">
                      <i
                        className="fas fa-ellipsis-h"
                        onClick={() => {
                          props.setShowHideCOmm(
                            props.showHideCOmm ? "" : item.id
                          );
                        }}
                      ></i>
                    </a>
                  </div>
                </div>
                <div
                  className={`post-reat-list ${
                    props.showHideCOmm == item.id ? "" : " d-none"
                  }`}
                >
                  <h4>
                    Reactions (
                    {(item && item.likes && item.likes.length
                      ? item.likes.length
                      : 0) +
                      (item && item.dislikes && item.dislikes.length
                        ? item.dislikes.length
                        : 0) +
                      (item && item.love && item.love.length
                        ? item.love.length
                        : 0) +
                      (item && item.laugh && item.laugh.length
                        ? item.laugh.length
                        : 0) +
                      (item && item.surprised && item.surprised.length
                        ? item.surprised.length
                        : 0) +
                      (item && item.sad && item.sad.length
                        ? item.sad.length
                        : 0) +
                      (item && item.angry && item.angry.length
                        ? item.angry.length
                        : 0)}
                    )
                  </h4>
                  <ul>
                    {renderActionList(item.likes, index)}
                    {renderActionList(item.dislikes, index)}
                    {renderActionList(item.love, index)}
                    {renderActionList(item.laugh, index)}
                    {renderActionList(item.surprised, index)}
                    {renderActionList(item.sad, index)}
                    {renderActionList(item.angry, index)}
                  </ul>
                </div>
              </div>
              <div className="card-left assigned-title-blk">
                <div className="card-left-by-img online-status user-drop-down-img">
                  {(item.user && item.user.photo) || (item.adminuser && item.adminuser.photo) ? (
                    <img
                      src={
                        item.user && item.user.photo
                          ? item.user.photo
                          : item.adminuser && item.adminuser.photo
                          ? item.adminuser.photo
                          : null
                      }
                      alt={
                        item?.user?.fullname ||
                        item?.adminuser?.fullname ||
                        null
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.closest('.card-left-by-img').append(InitalRender(originalValues(item.user ? item.user.fullname : (item.adminuser ? item?.adminuser?.fullname : "") , 23)));
                        currentTarget.remove();
                      }} 
                    />
                  ) : (
                    item?.user?.fullname ? InitalRender(item?.user?.fullname, 23) : <img src={UnknownStudent} alt="Unknown User" />
                  )}
                  {item.user ? 
                    <span
                      className={
                        // "portal-status-icon " +
                        // (_.contains(props.online, item.user.id)
                        //   ? " online-u"
                        //   : " offline-u")
                        `portal-status-icon ${
                          item.user?.activity_status?.toLowerCase() == "online"
                            ? "online-u"
                            : item.user?.activity_status?.toLowerCase() ==
                              "donotdisturb"
                            ? "donotdisturb-u"
                            : "offline-u"
                        }`
                      }
                    >
                      {" "}
                    </span>
                  : 
                    <span
                      className={`portal-status-icon border-0`
                      }
                    >
                      <img src={UnknownStudentStatus} alt="unknown" />
                    </span>
                  }
                </div>
                <UserHover
                  user={item.user}
                  hideEmail={true}
                  online={`portal-status-icon ${
                    item.user?.activity_status?.toLowerCase() == "online"
                      ? "online-u"
                      : item.user?.activity_status?.toLowerCase() ==
                        "donotdisturb"
                      ? "donotdisturb-u"
                      : "offline-u"
                  }`}
                />
                {/* <div className="assigned-title-info">
                  <article>
                    <figure className="bgBlue">
                      <div className="user-drop-down-img">
                        {item.user.photo ?
                          // <img src={item.user.photo || null} alt={item.user.fullname || null} /> 
                          <img src={item.user.photo} onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.closest('.bgBlue').append(InitalRender(originalValues(item.user.fullname, 23)));
                            currentTarget.remove();
                          }} />
                          : InitalRender(originalValues(
                            item.user.fullname
                          ))}
                        <span className={
                          // "portal-status-icon" + (_.contains(props.online, item.user.id) ? " online-u" : " offline-u")
                          `portal-status-icon ${item.user?.activity_status?.toLowerCase() == 'online' ? 'online-u' : item.user?.activity_status?.toLowerCase() == 'donotdisturb' ? 'donotdisturb-u' : 'offline-u'}`
                        }
                        ></span>
                      </div>
                    </figure>
                    <figcaption>
                      <h4>{originalValues(item.user.fullname)}</h4>
                      <p>
                        <i className="fal fa-envelope"></i>{" "}
                        <a className="hover-color-blue" onClick={() => copyEmailText(item.user.email)}>{item.user.email}</a>
                        {copyText ? (<i className="fas fa-check-circle cat-greenhex"></i>) : (item.user.email && <i className="fal fa-copy hover-color-blue" onClick={() => copyEmailText(item.user.email)} ></i>)}
                      </p>
                    </figcaption>
                  </article>
                </div> */}
              </div>
              <div className="card-right">
                <div className="d-flex justify-content-between">
                  <h5 className="comment-con">
                    <div className="profile-title-box-main">
                      <div className="profile-title-box">
                        <a href="#">{item.user?.fullname ? item.user.fullname : "Unknown User" }</a>
                        <span className="post-by-time">
                          {moment(
                            item.created_at,
                            "Do MMMM YYYY, h:mm:ss a"
                          ).format("dddd") +
                            ", " +
                            moment(
                              item.created_at,
                              "Do MMMM YYYY, h:mm:ss a"
                            ).format(Str.TABLE_DATE_TIME_FORMAT)}
                        </span>
                        {/* <div className="new-comment">
                          {props?.readMessages?.comment_id
                            ? item?.user?.id !== user.id &&
                              item.id > props?.readMessages?.comment_id
                              ? "New"
                              : null
                            : item?.user?.id !== user.id
                            ? "New"
                            : null}
                        </div> */}
                        <small>
                          {item.roleKey ? rollsWidget(item.roleKey) : ""}
                        </small>
                      </div>                        

                      <div className="main-thums-mob mob-profile-right">   
                        <div className="d-flex align-items-center">
                          <div className="mob-profile-dotted">
                            <i class="fas fa-ellipsis-v"></i>
                          </div>                     
                        </div>
                        <div className="tag-point-list">
                          <div className="row_icons_fas ls_box mob-discuss-icons  mob-post-static">
                            {item.important ? (
                              <i
                                className="fal fa-exclamation important-icon"
                                title="Important"
                              ></i>
                            ) : null}
                            {showTagIcon(
                              item.comment,
                              item.user_id,
                              props.user.id,
                              props.user,
                              item,
                              index
                            ) != "" ? (
                              <div
                                className={showTagIcon(
                                  item.comment,
                                  item.user_id,
                                  props.user.id,
                                  props.user,
                                  item,
                                  index
                                )}
                              >
                                <i className="fal fa-at" title="Tag"></i>
                              </div>
                            ) : null}

                            {item.attachments && item.attachments.length ? (
                              <i
                                className="fal fa-paperclip attachment-icon"
                                title="Attachment"
                              ></i>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </h5>
                </div>                
                <HtmlInputEditorShowComment
                  data={item.comment}
                  tagClassName={userTagColor(item.user_id, props.user.id)}
                  setCurrentVideo={props.setCurrentVideo}
                  editableArr={props.showHideEditArr}
                  allData={item}
                  sendClick={sendClickHandle}
                  importantArr={importantArr}
                  setIsImportant={importantHandle}
                  isImportant={item.important}
                  // attachmentClick={handleAttachment}
                  // editorState={editorState}
                  // setEditorState={setEditorState}
                  // isInValid={editorState}
                  userData={props.userData}
                  deletedFileHandle={deletedFileHandle}
                />
                <div className="chat-reaction-bar">
                  {item.likes.length ? (
                    <button className={"chat-message-reaction " + (isLiked(user.id, item.likes ? item.likes : []) ? "active" : "")} title="Like" onClick={() => {
                      props.likeDislikeHandle({
                        comment_id: item.id,
                        table_type: "comment",
                        comment_type: 1,
                        message_id: item.message_id,
                      });
                    }}>
                        <img className="chat-reaction-icon" src={ThumbsUpIcon} alt="Like" /> <span>{item.likes.length}</span>
                    </button>
                  ) : null}
                  {item.dislikes.length ? (
                    <button className={"chat-message-reaction " + (isDisliked(user.id, item.dislikes ? item.dislikes : []) ? "active" : "")} title="Dislike" onClick={() => {
                      props.likeDislikeHandle({
                        comment_id: item.id,
                        table_type: "comment",
                        comment_type: 2,
                        message_id: item.message_id,
                      });
                    }}>
                        <img className="chat-reaction-icon" src={ThumbsDownIcon} alt="Dislike" /> <span>{item.dislikes.length}</span>
                    </button>
                  ) : null}
                  {item.love.length ? (
                    <button className={"chat-message-reaction " + (isDisliked(user.id, item.love ? item.love : []) ? "active" : "")} title="Love" onClick={() => {
                      props.likeDislikeHandle({
                        comment_id: item.id,
                        table_type: "comment",
                        comment_type: 3,
                        message_id: item.message_id,
                      });
                    }}>
                        <img className="chat-reaction-icon" src={RedHeartIcon} alt="Love" /> <span>{item.love.length}</span>
                    </button>
                  ) : null} 
                  {item.laugh.length ? (
                    <button className={"chat-message-reaction " + (isLaughed(user.id, item.laugh ? item.laugh : []) ? "active" : "")} title="Laugh" onClick={() => {
                      props.likeDislikeHandle({
                        comment_id: item.id,
                        table_type: "comment",
                        comment_type: 4,
                        message_id: item.message_id,
                      });
                    }}>
                        <img className="chat-reaction-icon" src={GrinningIcon} alt="Laugh" /> <span>{item.laugh.length}</span>
                    </button>
                  ) : null}
                  {item.surprised.length ? (
                    <button className={"chat-message-reaction " + (isSurprised(user.id, item.surprised ? item.surprised : []) ? "active" : "")} title="Surprised" onClick={() => {
                      props.likeDislikeHandle({
                        comment_id: item.id,
                        table_type: "comment",
                        comment_type: 5,
                        message_id: item.message_id,
                      });
                    }}>
                        <img className="chat-reaction-icon" src={OpenFaceIcon} alt="Surprised" /> <span>{item.surprised.length}</span>
                    </button>
                  ) : null}
                  {item.sad.length ? (
                    <button className={"chat-message-reaction " + (isSad(user.id, item.sad ? item.sad : []) ? "active" : "")} title="Sad" onClick={() => {
                      props.likeDislikeHandle({
                        comment_id: item.id,
                        table_type: "comment",
                        comment_type: 6,
                        message_id: item.message_id,
                      });
                    }}>
                        <img className="chat-reaction-icon" src={FrowningIcon} alt="Sad" /> <span>{item.sad.length}</span>
                    </button>
                  ) : null} 
                  {item.angry.length ? (
                    <button className={"chat-message-reaction " + (isSad(user.id, item.angry ? item.angry : []) ? "active" : "")} title="Angry" onClick={() => {
                      props.likeDislikeHandle({
                        comment_id: item.id,
                        table_type: "comment",
                        comment_type: 7,
                        message_id: item.message_id,
                      });
                    }}>
                        <img className="chat-reaction-icon" src={PoutingIcon} alt="Angry" /> <span>{item.angry.length}</span>
                    </button>
                  ) : null}                      
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
export default React.memo(FeedComments);
