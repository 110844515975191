import React from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import {
  GetMyQualificationService,
  GetMyTimeTableService,
} from "../services/QualificationService";
import { Link } from "react-router-dom";
import SkeletonQaulification from "../loaders/SkeletonQualification";
import QualificationBox from "../components/course/QualificationBox";
import moment from "moment";
import HomeWishes from "../components/common/HomeWishes";
import { getCurrentTimeHomePage } from "../components/common/Helper";
import { UpdateStudentTimeZone } from "../services/CalendarService";
import PastQualificationBox from "../components/course/PastQualificationBox";

class MyCourses extends React.Component {
  mounted = true;

  constructor(props) {
    super(props);
    this.state = {
      qualifications: [],
      pastQualification : [],
      shortCourse: [],
      pastShortCourse : [],
      loading: false,
      showQual: true,
      totalQual: 0,
      totalSCourses: 0,
      showTabs: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    GetMyQualificationService()
      .then((res) => {
        if (this.mounted) {
          this.filterQualification(res.data ? res.data : []);
          // this.setState({ qualifications: res.data });
          // this.setState({ loading: false });
          // setTimeout(() => {
          //   this.setState({ loading: false });
          // }, 1000);
        }
      })
      .catch((error) => { });
    
    UpdateStudentTimeZone({
      timezone:Intl.DateTimeFormat().resolvedOptions().timeZone
    }).then((res)=>{
      if(res.status===200){
      }
    }).catch((err)=>{
      console.log(err);
    })
  }

  filterQualification(qualArr = []) {
    let qual = [], srtCrs = [], tQual = 0, tSrtCrs = 0, pastQual = [], pastSrtCrs = [];
    qualArr.map((e, i) => {
      if (e.qualification.program_type === "shortcourse") {
        if(e.qualification.isPast == false){
          srtCrs.push(e.qualification);
        }else{
          pastSrtCrs.push(e.qualification);
        }
        tSrtCrs = tSrtCrs + (e.qualification.commentCount ? parseInt(e.qualification.commentCount) : 0);
      } else {
        if(e.qualification.isPast == false){
          qual.push(e.qualification);
        }else{
          pastQual.push(e.qualification)
        }
        tQual = tQual + (e.qualification.commentCount ? parseInt(e.qualification.commentCount) : 0);
      }
    })
    if (qual.length === 0 && pastQual.length === 0) this.setState({ showQual: false });
    this.setState({ qualifications: qual });
    this.setState({ pastQualification: pastQual });
    this.setState({ shortCourse: srtCrs });
    this.setState({ pastShortCourse: pastSrtCrs });
    this.setState({ totalQual: tQual });
    this.setState({ totalSCourses: tSrtCrs });
    this.setState({ loading: false });
    this.checkCount();
    console.log("this", this);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleDownload = (qid) => {
    GetMyTimeTableService(qid)
      .then((res) => { })
      .catch((error) => { });
  };

  checkCount = () => {
    if (this.state.qualifications.length || this.state.pastQualification.length) {
      if (this.state.shortCourse.length || this.state.pastShortCourse.length) {
        this.setState({ showTabs: true });
      } else {
        this.setState({ showQual: true });
        this.setState({ showTabs: false });
      }
    } else if (this.state.shortCourse.length || this.state.pastShortCourse.length) {
      this.setState({ showQual: false });
      this.setState({ showTabs: false });
    }
  }

  render() {
    // console.log("qualifications ------> ", this.state.qualifications);
    // console.log("shortCourses   >>>>>>> ", this.state.shortCourse);
    return (
      <>
        <Header></Header>
        <div className="content-wrapper d-flex flex-wrap">
          <SideBar></SideBar>
          <div className="sidebar-right">
            <div className="mycourses-list-wrap mycourses-edit tab-sec">
              <div className="my-tickets-heading-blk flex-direction mb-4">
                <h4 className="text-left-align landing-heading heading_color_dynamic my-courses-title">
                  My <span> Courses</span>
                </h4>
                <div className="text-md-right">
                  <p className=""></p>
                </div>
                <div className="lecture-tabs-wrap lecture-time-box">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    {this.state.showTabs ? <>
                      <li className={`nav-item ${this.state.showQual ? 'active' : ''}`} title="Qualifications">
                        <a
                          id="pills-Posts-tab"
                          data-toggle="pill"
                          href="#pills-Posts"
                          role="tab"
                          aria-controls="pills-Posts"
                          aria-selected="true"
                          className={`nav-link active`}
                          // className={`nav-link ${this.state.showQual ? 'active' : ''}`}
                          onClick={() => { this.setState({ showQual: true }) }}
                        >
                          {/* <i className="far fa-comments"></i> */}
                          <i className="far fa-graduation-cap" aria-hidden="true"></i>
                          Qualifications
                          {this.state.totalQual != 0 && <div className="nav-fa-comments my-crs-head" title="You have unread messages">
                            <small className="notify-count">{this.state.totalQual ? this.state.totalQual < 10 ? this.state.totalQual : "9+" : 0}</small>
                            <i className="fal fa-comments"></i>
                          </div>}
                        </a>
                      </li>

                      <li className={`nav-item ${!this.state.showQual ? 'active' : ''}`} title="Short Courses">
                        <a
                          className={`nav-link`}
                          // className={`nav-link ${!this.state.showQual ? 'active' : ''}`}
                          // className={props.subjectContent.subject.convChatGroup == 1 ? "nav-link" : "nav-link active"}
                          id="pills-Overview-tab"
                          data-toggle="pill"
                          href="#pills-Overview"
                          role="tab"
                          aria-controls="pillsOverview"
                          aria-selected="false"
                          onClick={() => { this.setState({ showQual: false }) }}
                        >
                          {/* <i className="fal fa-id-card"></i> */}
                          <i className="fa-solid fa-ribbon"></i>
                          Short Courses
                          {this.state.totalSCourses != 0 && <div className="nav-fa-comments my-crs-head" title="You have unread messages">
                            <small className="notify-count">{this.state.totalSCourses}</small>
                            <i className="fal fa-comments"></i>
                          </div>}
                        </a>
                        {/* {this.state.totalQual != 0 && <div className="nav-fa-comments my-crs-head"><small className="notify-count">{this.state.totalQual ? this.state.totalQual : 0}</small></div>} */}
                      </li>
                    </> : null}
                  </ul>
                  <p className="date-time"> {getCurrentTimeHomePage(Date().toLocaleString())}</p>
                </div>
              </div>

              {/* <h4 className="my-md-2 my-0 landing-heading">
                My <span>Courses</span>
              </h4> */}

              {this.state.loading && <SkeletonQaulification />}
              {!this.state.loading && (
                <ul className="mycourses-ul-list list-unstyled quali_mycourse ">
                  {/* {this.state.qualifications.map((qualification) => { */}
                  {/* // const subjectId =
                    // qualification.subjects.others.length > 0
                    // ? qualification.subjects.others[0].id
                    // : qualification.subjects.finised.length > 0
                    // ? qualification.subjects.finised[0].id
                    // : 58; */}
                  {/* return ( */}
                  <QualificationBox
                    showQualification = {this.state.showQual}
                    qualification={this.state.showQual ? this.state.qualifications : this.state.shortCourse}
                    // key={qualification.id}
                    state={this.state}
                  //subjectId={subjectId}
                  />

                  {/* );
                  })} */}
                </ul>
              )}
              {((this.state.showQual && this.state.pastQualification.length>0) || (!this.state.showQual && this.state.pastShortCourse.length>0)) ? <button
                data-toggle="collapse"
                data-target="#completed-exam"
                aria-expanded="false"
                className={`hide collapsed ${this.state.showQual ? "hide-completed-program" : "hide-completed-course"}`}
              ></button> : null}
              <div className="mycourses-ul-list list-unstyled quali_mycourse collapse" id="completed-exam">
                <PastQualificationBox 
                state={this.state}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MyCourses;
