import React from "react";

const AssignmentStatus = (props) => {
    switch (props.status) {
      case "Open":
        return (
          <>
          <div className="assessment-03">
            <p className="as-blue-bg  as-widget">Open</p>
          </div>
          </>
        );
        break;
      case "Draft":
        return (
          <>
            <div className="assessment-03">
              <p className="as-green-bg as-widget">Draft</p>
            </div>
          </>
        );
        break;
      case "Submitted":
        return (
          <>
            <div className="assessment-03">
              <p className="as-green-bg as-widget">Submitted</p>
            </div>
          </>
        );
        break;
      case "Closed-Submitted":
        return (
          <>
            <div className="assessment-03">
              <p className="as-orange-bg as-widget">Closed-Submitted</p>
            </div>
          </>
        );
        break;

      case "Pending":
        return (
          <>
            <div className="assessment-03">
              <p className="as-orange-bg as-widget">Pending</p>
            </div>
          </>
        );
        break;
      case "Closed-Marked":
        return (
          <>
          <div className="assessment-03">
            <p className="as-blue-bg as-widget">Closed-Marked</p>
          </div>
          </>
        );
        break;
      case "Closed Not-Submitted":
        return (
          <>
          <div className="assessment-03">
            <p className="as-red-bg as-widget">Closed Not-Submitted</p>
          </div>
          </>
        );
        break;
      default:
        return (
          <>
            <div className="assessment-03">
              <p className="as-red-bg as-widget">Unknown Status</p>
            </div>
          </>
        );
        break;
    }
}
export default AssignmentStatus;
