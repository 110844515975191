import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { GetPinnedMessages } from '../../services/QualificationService';
import moment from 'moment'
import { useParams } from 'react-router-dom';

const tempFilterData = [
    {text : "Assessment",color : "cat-dark-blue",icon : "fa-ballot-check"},
    {text : "Classes",color : "cat-green",icon : "fa-calendar"},
    {text : "Notifications",color : "cat-yellow",icon : "fa-bell"},
    {text : "Mentions",color : "cat-purple",icon : "fa-at"},
    {text : "Replies",color : "cat-blue",icon : "fa-reply"},
    {text : "Unread",color : "cat-red",icon : "fa-comment-exclamation"},
]
export default function FeedToolBar(props) {
    const {sid} = useParams();
    const [showFilter, setShowFilter] = useState(false);
    const [showPinned, setShowPinned] = useState(false);
    const [tempFilter, setTempFilter] = useState([]);
    const [selectedItem, setSelectedItem] = useState([]);
    const [pinnedMessages, setPinnedMessages] = useState([]);
    
    useEffect(() =>{
        return (()=>{            
            props.setAssessmentFilter(0)
            props.setClassFilter(0)
            props.setNotificationFilter(0)
            props.setMentionFilter(0)
            props.setReplyFilter(0)
            props.setUnReadFilter(0)
        })
    },[])
    
    useEffect(()=>{
        const data = tempFilterData.filter(item => !tempFilter.includes(item.text))
        if(data==0){
            setShowFilter(false);
        }
    },[tempFilter])

    const handleFilter = (input) =>{
        const ls = [...tempFilter];
        const allSelected = [...selectedItem];
        ls.push(input.text);
        allSelected.push(input);
        setTempFilter([...ls]);
        setSelectedItem([...allSelected]); 
        if(input.text==="Assessment"){
            props.setAssessmentFilter(1)
        }
        if(input.text==="Classes"){
            props.setClassFilter(1)            
        }
        if(input.text==="Notifications"){
            props.setNotificationFilter(1)                   
        }
        if(input.text==="Mentions"){
            props.setMentionFilter(1)            
        }
        if(input.text==="Replies"){
            props.setReplyFilter(1)            
        }
        if(input.text==="Unread"){
            props.setUnReadFilter(1)            
        }  
        setShowFilter(false)      
    }

    const removeFilterItem = (input, index) =>{
        const ls = [...tempFilter];
        const allSelected = [...selectedItem];
        if(index === -1){
            index = allSelected.findIndex((item) => item.text===input.text);
        }
        ls.splice(index, 1);
        allSelected.splice(index, 1);
        setTempFilter([...ls]);
        setSelectedItem([...allSelected]);
        if(input.text==="Assessment"){
            props.setAssessmentFilter(0)
        }
        if(input.text==="Classes"){
            props.setClassFilter(0)            
        }
        if(input.text==="Notifications"){
            props.setNotificationFilter(0)                   
        }
        if(input.text==="Mentions"){
            props.setMentionFilter(0)            
        }
        if(input.text==="Replies"){
            props.setReplyFilter(0)            
        }
        if(input.text==="Unread"){
            props.setUnReadFilter(0)            
        } 
    }

    useEffect(()=>{
        if(showPinned){
            const payload = { 
                room_id: sid ? sid : props.subjectContent.subject.id, 
            }
            GetPinnedMessages(payload).then((res)=>{
                if(res.status === 200){
                    setPinnedMessages(res?.data)
                }
            })
        }
    },[showPinned, sid])

    const handleShowMessage = (msg) =>{
        props.showMessage(0, msg.id)
    }

    return (
        <div className='feed-header'>
            <div className='feed-tool-bar'>
                <div className='search-bar-container'>
                    <div className='feed-search-bar news-room-search'>
                        <i className="fal fa-search left-icon"></i>
                        <input 
                            className='feed-search-input form-control' 
                            placeholder='Search'
                            name="search"
                            title="search"
                            value={props?.feedSearch}
                            onChange={(e) => props?.setFeedSearch(e.target.value)}
                        />
                        <div className='filter-item-container'>
                            {selectedItem.slice(0,1).map((item, idx)=>(
                                <span className='feed-filter-item' key={idx}>
                                    <i className={`cat ${item.color} fal ${item.icon} mr-1`}></i>
                                    {item.text}
                                    <button onClick={()=>{removeFilterItem(item, idx)}} className='remove-filter-item'><i className='fal fa-times'></i></button>
                                </span>
                            ))}
                        </div>
                        <span onClick={()=>{setShowFilter(!showFilter)}}>
                            <i className="fal fa-filter right-icon curser" title='Filter'></i>
                            {selectedItem.length > 0 ? <small className='feed-filter-count'>{selectedItem.length}</small> : null}
                        </span>
                    </div>
                    <div className={'feed-filter-dropdown ' + (showFilter ? "show" : "")}>
                        <ul className='feed-filter-group'>
                            {selectedItem.map((item, idx) =>(
                                <li key={idx} className='feed-filter-item'>
                                    <i className={`cat ${item.color} fal ${item.icon}`}></i> {item.text} <i className='fal fa-times' style={{color:'#467fcf'}} onClick={()=>{removeFilterItem(item, -1)}}></i>
                                </li>
                            ))}
                            {(tempFilterData.filter(item => !tempFilter.includes(item.text))).map((item, idx)=>(
                                <li key={idx} onClick={()=>{handleFilter(item)}} className='feed-filter-item'>
                                    <i className={`cat ${item.color} fal ${item.icon}`}></i> {item.text}
                                </li>
                            ))}
                        </ul>
                    </div>            
                </div>
                <div className='feed-tool-actions'>
                    <button 
                        className='btn btn-white-bordered mr-2' 
                        onClick={()=>{props.showMessage()}}
                        title="Show All Messages"
                    >
                        <i className="fal fa-folder-open"></i> Show All Messages
                    </button>
                    <button 
                        className='btn btn-white-bordered mr-2' 
                        onClick={()=>{props.setTab()}}
                        title="Shared Files"
                    >
                        <i className="fal fa-folder-open"></i> Shared Files
                    </button>
                    <button 
                        className={'btn btn-white-bordered ' + (showPinned ? " active" : "")}
                        title="Pinned Message"
                        onClick={()=>{setShowPinned(!showPinned);}}
                    >
                        <i className="fal fa-thumbtack"></i>
                        Pinned Message
                    </button>
                </div>            
            </div>
            {showPinned ? 
            pinnedMessages.length > 0 ?
            pinnedMessages.map((message, key) =>(
                <div className='pinned-messages-block' key={key} onClick={()=>{handleShowMessage(message)}}>
                    <div className='card pinned-msg-item' title={message?.subject}>
                        <div className='pinned-msg-left'>
                            <div className='pinned-msg-title'>
                                <span className='pinned-msg-title-head'>
                                    {message?.type == 2 ? 
                                    <>
                                    <i className="fal pin-icon fa-calendar"></i> <span>Pinned Class: </span> 
                                    </>
                                    : message?.type == 3 ?
                                    <>
                                    <i className="fal pin-icon fa-ballot-check"></i> <span>Pinned Assessment: </span> 
                                    </>
                                    : message?.type == 1 ?
                                    <>
                                    <i className="fal pin-icon fa-bell-exclamation"></i> <span>Pinned Notification: </span> 
                                    </>
                                    :<> 
                                    <i className="fal pin-icon fa-thumbtack"></i> <span>Pinned Message: </span> 
                                    </>} 
                                </span>
                                <span className='pinned-msg-title-content'>{message?.subject}</span>                                
                            </div>
                        </div>
                        <div className='pinned-msg-right'>
                            <div className='pinned-msg-date'>
                                <i className="fal fa-calendar-alt"></i> {moment(message?.pin_date).format("DD MMM YY HH:mm")}
                            </div>
                            <div className='pinned-msg-action'>
                                <i title="View Message" onClick={()=>{handleShowMessage(message)}} className="fal fa-info-circle"></i>
                            </div>
                        </div>
                    </div>
                </div> 
            ))
            :<p className='text-center'>No Pinned Messages</p>
            : null}
        </div>
    )
}
