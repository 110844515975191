import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  ProfilePersonalDocService,
  RemoveMyPersonalDocumentsService,
  GetMyPersonalDocService,
} from "../../../services/ProfileService";
import DownloadCertificate from "./DownloadCertificate";

export default function PayerPersonalDocuments({ docType }) {

    const [personalDocuments, setPersonalDocuments] = useState([]);
    const file = useSelector((state) => state.file);
    const [loading, setloading] = useState(false);

    useEffect(() => {
        let mounted = true;
        GetMyPersonalDocService({ dt: docType })
            .then((res) => {
                if (mounted) {
                    setloading(true);
                    setPersonalDocuments(res.data);
                }
            })
            .catch((error) => { });

        return function cleanup() {
            setPersonalDocuments([]);
            mounted = false;
        };
    }, [file]);

    const handleFileChange = (e) => {
        if (e.target.files.length) {
            const formInputs = new FormData();
            formInputs.append("doc", e.target.files[0]);
            formInputs.append("dt", docType);

            Swal.fire({
                title: "File Uploading....",
                onOpen: function () {
                    Swal.showLoading();
                },
            });
            ProfilePersonalDocService(formInputs)
                .then((res) => {
                    Swal.close();
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Updated successfully",
                    }).then((result) => {
                        setPersonalDocuments(res.data);
                    });
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.response.data.message,
                    }).then((result) => { });
                });
        }
    };
    const handleRemoveFile = (rowId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                RemoveMyPersonalDocumentsService({ rowId, dt: docType })
                    .then((res) => {
                        Swal.fire({
                            icon: "success",
                            title: "Success",
                            text: "Personal document removed successfully",
                        }).then((result) => {
                            if (result.value) {
                                setPersonalDocuments(res.data);
                            }
                        });
                    })
                    .catch((error) => {
                        //console.log(error);
                    });
            }
        });
    };

    return (
        <>
            {loading && (
                <>
                    <div className="frm-group">
                        <ul className="list-unstyled attached-file-ul m-0 account-payer-doc-list">
                            {personalDocuments.map((document) => {
                                return (
                                    <li className="" key={document.id}>
                                        <DownloadCertificate
                                            item={document}
                                            canRemove={true}
                                            handleRemoveFile={handleRemoveFile}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="form-group atttach-file m-0">
                        <label>
                            <i className="fal fa-paperclip"></i>
                            <span>Add Attachment</span>
                            <input
                                type="file"
                                className="form-control  form-control-aatch-file"
                                onChange={(e) => handleFileChange(e)}
                            />
                        </label>
                    </div>
                </>
            )}
        </>
    )
}
