import React, { useEffect, useState } from "react";
import LoginDetails from "./blocks/LoginDetails";
import { useDispatch, useSelector } from "react-redux";
import { countryList, GetPayerDetailService, UpdateUserDetails } from "../../services/ProfileService";
import { setPayer, setUser } from "../../store/actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import Select from "react-select";
import { capitalize, languageListOptions, nationalityListOptions, disabilityListOption, provinceOptions, raceOptions, genderOptions, titleOptions} from "../../utils/commonFunction";
import moment from "moment";
import { EMAIL_REGEX, MOBILE_REGEX } from "../../utils/Constants";
import ProfilePersonalDocuments from "./blocks/ProfilePersonalDocuments";

const PersonalInformation = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [copyText, setCopyText] = useState(0);
  const [dateDropdown, setDateDropdown] = useState({
    year:[],
    day:[],
    month:[]
  });
  const [countries, setCountries] = useState([]);
  let details; 
  let brand;
  
  let user = useSelector(state => state.user)

  if (localStorage.getItem("user") != null) {
    details = JSON.parse(localStorage.getItem("user"));
    //console.log("details--- ", details.email);
  }

  if (localStorage.getItem("brandVariables") != null) {
    brand = JSON.parse(localStorage.getItem("brandVariables"));
    //console.log("details--- ", details.email);
  }

  const copyEmailText = (email) => {
    setCopyText(1);
    navigator.clipboard.writeText(email);
    setTimeout(() => {
        setCopyText(0)
    }, 1000);
  }

  const handleDateDropdownOptions = () => {
    let yearOptions = [];
    let dayOptions = [];
    for (let index = 1900; index < moment().year(); index++) {
      yearOptions.push({value:index, label:index});
    }
    
    for (let index = 1; index <= 31; index++) {
      dayOptions.push({value:index, label:index});
    }

    let monthOptions = moment.months().map((month, index) => {
      return (
        { value: index + 1, label: month }
      );
    })
    setDateDropdown({ 
      ...dateDropdown,
      year: yearOptions, 
      day: dayOptions,
      month: monthOptions 
    });
  };
  
  useEffect(() => {
    handleDateDropdownOptions();
    countryList()
    .then((res) => {
      const respData = res.data.data;
      const countryOpt = respData.map((item) => {
        return (
          { value: item.value, label: item.value }
        );
      })
      setCountries([...countryOpt]);
    })
    .catch((error) => {
      setCountries([]);
    });
    GetPayerDetailService()
      .then((res) => {
        dispatch(setPayer(res.data[0]));
      })
      .catch((error) => {});
  }, []);

  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    title: Yup.string().required("Title is required"),
    number: Yup.string().required("ID Number is required"),
    bday_year: Yup.string().required("Year is required"),
    bday_month: Yup.string().required("Month is required"),
    bday_day: Yup.string().required("Day is required"),
    race: Yup.string().required("Race is required"),
    nationality: Yup.string().required("Nationality is required"),
    disability: Yup.string().required("Disablity is required"),
    gender: Yup.string().required("Gender is required"),
    language: Yup.string().required("Language is required"),
    email: Yup.string().email("Invalid email address").required("Email is required").matches(EMAIL_REGEX, "Invalid email address"),
    mobile: Yup.string().matches(MOBILE_REGEX,"Phone number should be 0-9 of 10 digits with country code").required("Mobile is required"),
    phone: Yup.string().matches(MOBILE_REGEX,"Phone number should be 0-9 of 10 digits with country code"),
    a_phone: Yup.string().matches(MOBILE_REGEX,"Phone number should be 0-9 of 10 digits with country code"),
    ph_street: Yup.string().required("Street address is required"),
    ph_city: Yup.string().required("City is required"),
    ph_province: Yup.string().required("Province is required"),
    ph_country: Yup.string().required("Country is required"),
    ph_zip: Yup.string().required("Postal Code is required"),
  })

  const formik = useFormik({
    enableReinitialize:true,
    initialValues:{
      first_name: user?.first_name ? user?.first_name : "",
      second_name: user?.second_name ? user?.second_name : "",
      third_name: user?.third_name ? user?.third_name : "",
      last_name: user?.last_name ? user?.last_name : "",
      title: user?.title ? user?.title : "",
      number: user?.number ? user?.number : "",
      bday_year: user?.bday_year ? user?.bday_year : "",      
      bday_month: user?.bday_month ? user?.bday_month : "",
      bday_day: user?.bday_day ? user?.bday_day : "",
      race: user?.race ? user?.race : "",
      nationality: user?.nationality ? user?.nationality : "",
      disability: user?.disability ? user?.disability : "",
      gender: user?.gender ? user?.gender : "",
      language: user?.language ? user?.language : "",
      email: user?.email ? user?.email : "",
      mobile: user?.mobile ? user?.mobile : "",
      phone: user?.phone ? user?.phone : "",
      a_phone: user?.a_phone ? user?.a_phone : "",
      ph_street: user?.ph_street ? user?.ph_street : "",
      ph_city: user?.ph_city ? user?.ph_city : "",
      ph_province: user?.ph_province ? user?.ph_province : "",
      ph_country: user?.ph_country ? user?.ph_country : "",
      ph_zip: user?.ph_zip ? user?.ph_zip : "",
    },
    validationSchema,
    onSubmit : (values) =>{
      setLoading(true);
      UpdateUserDetails(values).then((res)=>{
        if(res.status == 200){
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          }).then((result) => {
            if (result.value) {
              dispatch(setUser({...user, ...res.data}));              
            }
          });
        }
      }).catch((err)=>{
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.message,
        });
      }).finally(()=>{
        setLoading(false);
      })
    }
  })

  const { values, errors, setFieldValue, touched, handleChange, handleBlur, handleSubmit } = formik;

  return (
    <>      
      <div className="card card-profile-info-card">
        <div className="card-header mb-4">Personal Information</div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group" title="First Name">
                <label>First Name*</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errors.first_name && touched.first_name
                        ? " is-invalid"
                        : "")
                    }
                    placeholder="First Name"
                    value={values.first_name}
                    name="first_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                <p className="form-info-text"><i class="fal fa-info-circle "></i> As Per Your ID Document</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group" title="Second Name">
                <label>Second Name(Optional)</label>
                  <input
                    type="text"
                    className={"form-control"}
                    placeholder="Second Name"
                    value={values.second_name}
                    name="second_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group" title="Third Name">
                <label>Third Name (Optional)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Third Name"
                    value={values.third_name}
                    name="third_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group" title="Last Name">
                <label>Last Name *</label>
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (errors.last_name && touched.last_name
                        ? " is-invalid"
                        : "")
                    }
                    placeholder="Last Name"
                    value={values.last_name}
                    name="last_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
              </div>
            </div> 
            <div className="col-md-3">
              <div className="form-group" title="Title">
                <label>Title *</label>
                <div className="form-icon-group">
                  <Select
                    className={
                      "form-control custom-select-box" +
                      (errors.title && touched.title
                        ? " is-invalid"
                        : "")
                    }
                    name="title"
                    value={titleOptions.find(item => item.value == values.title)}
                    onChange={(value) => { setFieldValue("title", value.value) }}
                    onBlur={handleBlur}
                    maxMenuHeight={175}
                    placeholder={values.title ? capitalize(values.title) : "Title"}
                    options={titleOptions}
                  />                    
                </div>
              </div>
            </div>  
            <div className="col-md-3">
              <div className="form-group" title="Nationality">
              <label>Nationality </label>
              <div className="form-icon-group">
                <Select
                  className={
                    "form-control custom-select-box" +
                    (errors.nationality && touched.nationality
                      ? " is-invalid"
                      : "")
                  }
                  name="nationality"
                  value={nationalityListOptions.find(item => item.value == values.nationality)}
                  onChange={(value) => {
                    setFieldValue("nationality", value.value)
                  }}
                  onBlur={handleBlur}
                  maxMenuHeight={175}
                  placeholder={"Nationality"}
                  options={nationalityListOptions}
                />                  
              </div>
              </div>
            </div>   
            <div className="col-md-3">
              <div className="form-group" title="Gender">
              <label>Gender *</label>
                <div className="form-icon-group">
                  <Select
                    className={
                      "form-control custom-select-box" +
                      (errors.gender && touched.gender
                        ? " is-invalid"
                        : "")
                    }
                    name="gender"
                    value={genderOptions.find(item => (item.value).toLowerCase() ==  (values.gender).toLowerCase())}
                    onChange={(value) => { setFieldValue("gender", value.value) }}
                    onBlur={handleBlur}
                    maxMenuHeight={175}
                    placeholder={values.gender ? capitalize(values.gender) : "Gender"}
                    options={genderOptions}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group" title="ID/Passport Number">
                <label>ID/Passport Number *</label>
                <input
                  type="text"
                  className={
                    "form-control" +
                    (errors.number && touched.number
                      ? " is-invalid"
                      : "")
                  }
                  placeholder="ID/Passport Number"
                  name="number"
                  value={values.number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>   
            <div className="col-md-3">
              <div className="form-group" title="Home Language">
              <label>Home Language *</label>
                <div className="form-icon-group">
                  <Select
                    className={
                      "form-control custom-select-box" +
                      (errors.language && touched.language
                        ? " is-invalid"
                        : "")
                    }
                    name="language"
                    value={languageListOptions.find(item => (item.value).toLowerCase() ==  (values.language).toLowerCase())}
                    onChange={(value) => { setFieldValue("language", value.value) }}
                    onBlur={handleBlur}
                    maxMenuHeight={175}
                    placeholder={"Language"}
                    options={languageListOptions}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group" title="Race">
              <label>Race *</label>
                <div className="form-icon-group">
                  <Select
                    className={
                      "form-control custom-select-box" +
                      (errors.race && touched.race
                        ? " is-invalid"
                        : "")
                    }
                    name="race"
                    value={raceOptions.find(item => (item.value) ==  (values.race))}
                    onChange={(value) => { setFieldValue("race", value.value) }}
                    onBlur={handleBlur}
                    maxMenuHeight={175}
                    placeholder={"Race"}
                    options={raceOptions}
                  />                    
                </div>
              </div>
            </div> 
            <div className="col-md-6">
              <div className="form-group date-form-group">
                <label>Date Of Birth *</label>
                <div className="row form-icon-group">                                      
                  <div className="col-3">
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (errors.bday_day && touched.bday_day
                          ? " is-invalid"
                          : "")
                      }
                      name="bday_day"
                      value={dateDropdown.day.find(item => item.value ==  values.bday_day)}
                      onChange={(value) => { setFieldValue("bday_day", value.value) }}
                      onBlur={handleBlur}
                      maxMenuHeight={175}
                      placeholder={"Day"}
                      options={dateDropdown.day}
                    />
                    {errors.bday_day && touched.bday_day ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                  <div className="col-5">
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (errors.bday_month && touched.bday_month
                          ? " is-invalid"
                          : "")
                      }
                      name="bday_month"
                      value={dateDropdown.month.find(item => item.value ==  values.bday_month)}
                      onChange={(value) => {
                        setFieldValue("bday_month", value.value)
                      }}
                      onBlur={handleBlur}
                      maxMenuHeight={175}
                      placeholder={"Month"}
                      options={dateDropdown.month}
                    />
                    {errors.bday_month && touched.bday_month ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                  <div className="col-4">
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (errors.bday_year && touched.bday_year
                          ? " is-invalid"
                          : "")
                      }
                      name="bday_year"
                      value={dateDropdown.year.find(item => item.value ==  values.bday_year)}
                      onChange={(value) => {
                        setFieldValue("bday_year", value.value)
                      }}
                      onBlur={handleBlur}
                      maxMenuHeight={175}
                      placeholder={"Year"}
                      options={dateDropdown.year}
                    />
                    {errors.bday_year && touched.bday_year ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>  
                </div>
              </div>
            </div>          
            <div className="col-md-3">
              <div className="form-group" title="Disability">
              <label>Disability </label>
                <div className="form-icon-group">
                  <Select
                    className={
                      "form-control custom-select-box" +
                      (errors.disability && touched.disability
                        ? " is-invalid"
                        : "")
                    }
                    name="disability"
                    value={disabilityListOption.find(item => item.value == values.disability)}
                    onChange={(value) => {
                      setFieldValue("disability", value.value)
                    }}
                    onBlur={handleBlur}
                    maxMenuHeight={175}
                    placeholder={"Disability"}
                    options={disabilityListOption}
                  />                  
                </div>
              </div>
            </div>                 
          </div>
          <hr />
          <div className="card-header mb-4">Contact Information</div>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group" title="Email">
                <label>Email*</label>
                <input
                  type="email"
                  className={
                    "form-control" +
                    (formik.errors.email && formik.touched.email
                      ? " is-invalid"
                      : "")
                  }
                  name="email"
                  title="Email"
                  placeholder="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div> 
            <div className="col-md-3">
              <div className="form-group" title="Mobile">
                <label>Mobile Number*</label>
                <input
                  type="text"
                  className={
                    "form-control" +
                    (formik.errors.mobile && formik.touched.mobile
                      ? " is-invalid"
                      : "")
                  }
                  name="mobile"
                  title="Mobile"
                  placeholder="Mobile"
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div> 
            <div className="col-md-3">
              <div className="form-group" title="Landline">
                <label>Landline </label>
                <input
                  type="text"
                  className={
                    "form-control" +
                    (formik.errors.phone && formik.touched.phone
                      ? " is-invalid"
                      : "")
                  }
                  name="phone"
                  title="Landline"
                  placeholder="Landline"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group" title="Alternative Contact Number">
                <label>Alternative Contact Number </label>
                <input
                  type="text"
                  className={
                    "form-control" +
                    (formik.errors.a_phone && formik.touched.a_phone
                      ? " is-invalid"
                      : "")
                  }
                  name="a_phone"
                  title="Alternative Contact Number"
                  placeholder="Alternative Contact Number"
                  value={formik.values.a_phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>          
          </div>
          <hr />
          <div className="card-header mb-4">Address Information</div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group" title="Street Address">
                <label>Street Address *</label>
                <input
                  type="text"
                  className={
                    "form-control" +
                    (errors.ph_street && touched.ph_street
                      ? " is-invalid"
                      : "")
                  }
                  name="ph_street"
                  placeholder="Street Address"                    
                  value={values.ph_street}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div> 
            <div className="col-md-3">
              <div className="form-group" title="City">
                <label>City *</label>
                <input
                  type="text"
                  className={
                    "form-control" +
                    (errors.ph_city && touched.ph_city
                      ? " is-invalid"
                      : "")
                  }
                  name="ph_city"
                  placeholder="City"                    
                  value={values.ph_city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group" title="Postal Code">
                <label>Postal Code *</label>
                <input
                  type="text"
                  className={
                    "form-control" +
                    (errors.ph_zip && touched.ph_zip
                      ? " is-invalid"
                      : "")
                  }
                  name="ph_zip"
                  value={values.ph_zip}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div> 
            <div className="col-md-3">
              <div className="form-group" title="Province">
                <label>Province *</label>
                <div className="form-icon-group">
                  <Select
                    className={"form-control custom-select-box" + (errors.ph_province && touched.ph_province ? " is-invalid" : "")}
                    name="ph_province"
                    value={provinceOptions.find(item => item.value == values.ph_province)}
                    onChange={(value) => setFieldValue("ph_province", value.value)}
                    onBlur={handleBlur}
                    options={provinceOptions}
                    maxMenuHeight={175}
                    placeholder={"Province"}
                  />                    
                </div>
              </div>
            </div> 
            <div className="col-md-3">
              <div className="form-group" title="Country">
                <label>Country *</label>
                <div className="form-icon-group">
                  <Select
                    className={
                      "form-control custom-select-box" +
                      (errors.ph_country && touched.ph_country
                        ? " is-invalid"
                        : "")
                    }
                    name="ph_country"
                    value={countries.find(item => item.value == values.ph_country)}
                    onChange={(value) => setFieldValue("ph_country", value.value)}
                    onBlur={handleBlur}
                    maxMenuHeight={175}
                    placeholder={"Country"}
                    options={countries}
                  />
                </div>
              </div>
            </div>                              
          </div>
          <div className="form-group form-group-save-cancel">              
            <button
              className="btn btn-save btn-success"
              type="submit"
              title="Save"
              disabled={!formik.dirty || loading}
            >
              {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
              Save Changes
            </button>
            <button
              className="btn btn-close btn-danger"
              type="button"
              onClick={() => {formik.resetForm()}}
              title="Cancel"
            >
              <i className="fal fa-times"></i>
              Cancel
            </button>
          </div>
          {Object.keys(formik.errors).map((key)=>(
            Array.isArray(formik.errors[key]) && Array.isArray(formik.touched[key]) ? 
              <div className="invalid-feedback d-block">
                {formik.errors[key][0].subjectName}
              </div>
            : formik.touched[key] && formik.errors[key] ? (
              <div className="invalid-feedback d-block">
                {formik.errors[key]}
              </div>
            ) : null
          ))}  
        </form>        
      </div>
      <div className="card card-profile-info-card">
        <div className="card-header mb-4">Login Details</div>
        <div className="card-body-inr">
          <LoginDetails />
          <hr />
          <div className="row">
              <div className="col-md-6">
                <div className="card-header mb-4">Your Personal Documents</div>
                <ProfilePersonalDocuments 
                  docType={"personal_doc"}
                />
              </div>
              {(!brand?.domain_url.includes("learnfast") && !brand?.domain_url.includes("cadco")  && user?.student_brand_url && !user?.student_brand_url.includes("learnfast") && !user?.student_brand_url.includes("cadco")) ?
              <div className="col-md-6">
                <div className="card-header mb-4">Email Account Details</div>
                <div className="form-group">
                  <label>Email</label>
                  <div className="account-email">
                    {details?.userPrincipalName ? 
                      <>
                      {details.userPrincipalName}{" "}
                      <span>
                        {copyText ? (<i className="fas fa-check-circle cat-greenhex"></i>) : (<i className="fal fa-copy hover-color-blue" onClick={() => copyEmailText(details.userPrincipalName)}></i>)}
                      </span>
                      </>                      
                    : ""}
                  </div>
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <div className="account-pass-message">                    
                    Your password is the same as your Student Portal
                    Login Password
                  </div>
                </div>
              </div>
              :null}
          </div>
        </div>        
      </div>
    </>
  );
};

export default PersonalInformation;
