import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ResizableTextarea from "../common/ResizableTextarea";
import TextareaAutosize from "react-autosize-textarea";
import { FilePond, File, registerPlugin, setOptions } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { API_BASE } from '../../utils/Constants';
import { getToken, removeUserSession } from "../../utils/Auth";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "../../assets/css/filePond.css";


/** mention users in editor */
import { MentionsInput, Mention } from 'react-mentions'
import defaultStyle from '../../utils/defaultStyle'
import defaultMentionStyle from '../../utils/defaultMentionStyle'


/** emoji plugin */
//import 'emoji-mart/css/emoji-mart.css'
//import { Picker } from 'emoji-mart'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, RichUtils, CompositeDecorator, ContentState, convertFromHTML, convertFromRaw } from "draft-js";
import HtmlInputEditor from "../common/HtmlInputEditor";
import draftToHtml from "draftjs-to-html";
import UserHover from "../common/UserHover";

registerPlugin(FilePondPluginImagePreview);


const FeedMessageForm = (props) => {
  const user = useSelector((state) => state.user);
  const [InptMessage, setInptMessage] = useState("");
  const postTxt = createRef();
  const btnAttachment = createRef();
  const filePondRef = createRef();
  const [files, setFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [showFilePond, setShowFilePond] = useState(false);
  const [removeFile, setRemoveFile] = useState(null);
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [copyText, setCopyText] = useState(0);
  const [isImportant, setIsImportant] = useState(false);
  const [users, setUsers] = useState('');
  const [showEditor, setShowEditor] = useState(false);
  const [resetFeed, setResetFeed] = useState(1);
  const [inputError, setInputError] = useState('');
  const [subjectLine, setSubjectLine] = useState('');
  const [showcurvalue, setShowcurValue] = useState(false)

  const InitalRender = useMemo(() => (data) => {
    if (data != null && data != undefined && data) {
      let tempArr = data.split(" ")
      let initials = ''
      tempArr.map(i => {
        if (i) {
          initials = initials + i[0].toUpperCase();
        }
      })
      initials = initials.length > 2 ? initials.slice(0, 2) : initials
      return initials
    }
    return 'NA'
  
  },[])

  const originalValues = useCallback((s) => {
    try {
      return decodeURIComponent(escape(s));
    } catch (error) {
      return s;
    }
  },[])

  const HANDLE_REGEX = /\@[\w]+/g;
  const styles = {
    editor: { border: "1px solid #ddd", cursor: "text", fontSize: 16, minHeight: "40px", padding: 10, background: "#000" },
    handle: { color: "rgba(98, 177, 254, 1.0)", direction: "ltr", unicodeBidi: "bidi-override" },
  };

  function findWithRegex(regex, contentBlock, callback) {
    const text = contentBlock.getText();
    let matchArr, start;
    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index;
      callback(start, start + matchArr[0].length);
    }
  }

  useEffect(() => {
    if (props.taggingUsers && props.taggingUsers.length > 0) {
      let usersArr = [...props.taggingUsers]
      usersArr.map(i => {
        i.text = i.display
        i.value = i.display
        i.url = i.id + "-" + i.type
      })
      usersArr.push({text:"everyone", value:"everyone", url:"everyone-users", id:"everyone",display:"everyone", type:"users"})
      setUsers([...usersArr])
    }
  }, []);

  // useEffect(()=>{
  //   if(attachments.length<1){
  //     console.log('the size of the attachments are ', attachments)
  //     setShowFilePond(false)
  //   }
  // },[attachments])

  const HandleSpan = useCallback((props) => {
    return (
      <span style={styles.handle} data-offset-key={props.offsetKey}>
        {props.children}
      </span>
    );
  },[]);

  function handleStrategy(contentBlock, callback, contentState) {
    findWithRegex(HANDLE_REGEX, contentBlock, callback);
  }

  const compositeDecorator = new CompositeDecorator([{ strategy: handleStrategy, component: HandleSpan }]);
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());

  useEffect(() => {
    let tempBlock = convertToRaw(editorState.getCurrentContent())
    if ((tempBlock && tempBlock.blocks && tempBlock.blocks.length > 1) || (tempBlock && tempBlock.blocks && (tempBlock.blocks[0].text != ""))) {
      setInptMessage('yes')
    }
    else {
      setInptMessage('')
    }
  }, [editorState]);

  const handleInptMessage = (e) => {
    setInptMessage(e.target.value);
    props.TypingMessage();
  };

  const handleBtnMessage = () => {
    const messageValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    let blocks = messageValue.blocks
    if (subjectLine == '') {
      setInputError('Please add subject')
    }
    else {

      // blocks.splice(0, 0, {
      //   data: {},
      //   depth: 0,
      //   entityRanges: [],
      //   inlineStyleRanges: [
      //     { offset: 0, length: subjectLine.length, style: 'BOLD' },
      //     { offset: 0, length: subjectLine.length, style: 'fontsize-16' }
      //   ],
      //   key: "1sjda",
      //   text: subjectLine,
      //   type: "unstyled"
      // })
      // blocks.splice(1, 0, {
      //   data: {},
      //   depth: 0,
      //   entityRanges: [],
      //   inlineStyleRanges: [],
      //   key: "1sjdb",
      //   text: '',
      //   type: "unstyled"
      // })
      // messageValue.blocks = blocks

      // const messageValueUpdated = JSON.stringify(messageValue);

      const entityMap = convertToRaw(editorState.getCurrentContent()).entityMap;
      const mentions = [];
      Object.values(entityMap).forEach(entity => {
        if (entity.type === 'MENTION') {
          mentions.push(entity.data.text);
        }
      });

      let messagpost = {
        subject: subjectLine,
        message: messageValue,
        files: attachments,
        sizes: sizes,
        important: isImportant,
        taggedUsers: mentions
      };

      props.pSendMessage(messagpost);
      setEditorState(EditorState.push(editorState, ContentState.createFromText('')))
      setInptMessage("");
      setFiles([]);
      setAttachments([]);
      setShowFilePond(false);
      setInputError('')
      setSubjectLine('')

      setResetFeed(Math.floor((Math.random() * 10000) + 1))
      setTimeout(() => {
        // setShowEditor(false)
        props.setShowNewStatus(false)
        setStateEditorShow(false)
      }, 1000);
    }
  };

  const handleAttahment = () => {
    setShowFilePond(true);
    btnAttachment.current.click();
  };

  const handleFiles = async (event) => {
    const ufiles = await event.target.files;
    if (ufiles.length > 0) {
      if (ufiles[0]) {
        // if(filePondRef.current) {
        filePondRef.current.addFile(ufiles[0])
          .then((file) => {
          })
          .catch((error) => console.log(error));
        // }
      }
    }
  };

  const handleUploadedFiles = (response) => {
    // setAttachments((attachments) => [...attachments, response]);
    let newObj = {}
    let blocks = isJsonString(response)
    if (blocks) {
      newObj = JSON.parse(response)
      setAttachments((attachments) => [...attachments, newObj.fullDocFilePath]);
      setSizes((pre) => [...pre, newObj.size]);
    }
  }

  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const handlePaste = (e) => {
    if (e.clipboardData.types[0] == 'Files') {

      if (e.clipboardData.items == undefined) {
        return;
      };

      for (var i = 0; i < e.clipboardData.items.length; i++) {
        // Skip content if not image
        if (e.clipboardData.items[i].type.indexOf("image") == -1) continue;
        // Retrieve image on clipboard as blob
        var blob = e.clipboardData.items[i].getAsFile();
      }

      setTimeout(() => {
        filePondRef.current.addFile(blob)
      }, 1000);

      setShowFilePond(true);
      handleFilePondInit(e);
    }
  }

  const handleFilePondInit = () => {

  }

  const handleUserTagged = (user) => {
    const extendedList = taggedUsers;
    setTaggedUsers([...extendedList, user]);
  }

  const addEmoji = (emoji) => {
    const appendText = InptMessage + emoji.native;
    setInptMessage(appendText);

  }

  const copyEmailText = (e) => {
    setCopyText(1);
    navigator.clipboard.writeText(user.email);
    setTimeout(() => {
      setCopyText(0)
    }, 1000);
  }

  const handleDragEnter = event => {
    setShowFilePond(true);
  };

  const updateSubjectLine = (e) => {
    setSubjectLine((e.target.value).trimLeft())
  }

  const attachmentsArrUpdate = (file) => {
    let tempAttach = attachments
    for (var i = 0; i < tempAttach.length; i++) {
      if (tempAttach[i].includes(file.split('.')[0])) {
        tempAttach.splice(i, 1);
        break;
      }
    }
    // console.log('tempAttach value would be ', tempAttach)
    if (tempAttach.length < 1) {
      setTimeout(() => {
        setShowFilePond(false)
        setShowcurValue(!showcurvalue)
      }, 500);
    }
  }

  const editorRef = useRef(null);
  const [editorShow, setStateEditorShow] = useState(false);
  const handleClickOutside = (event) => {    
    if (
      editorRef.current
      && !editorRef.current.contains(event.target)
      && event.target.classList.contains('rdw-embedded-modal-btn') != true
      && event.target.classList.contains('rdw-suggestion-option') != true
    ) {
      setStateEditorShow(false)
    } else if (
      editorRef.current
      && editorRef.current.contains(event.target)
      && event.target.classList.contains('new_discussion') == true
    ) {
      setStateEditorShow(true)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className={"ps-fd-card-head new-discussion-box" + (editorShow ? " mt-4" : " mt-2")}>
        {/* <div className={props.showNewStatus ? "post-area post-by" : "post-area reply-profile-hide"}> */}
        <div className={editorShow ? "post-area post-by" : "post-area "}>
          <div className="post-area-left">

            <div className="post-area-img assigned-title-blk">
              <div className="post-by-img">
                {user.photo ?
                  <img src={user.photo} alt={user.first_name} />
                  :
                  InitalRender(originalValues(user.first_name + " " + user.last_name, 23))
                }
              </div>
              <div className={"post-by-status online-u"}></div>
              <UserHover
                user={user}
                online={
                  `portal-status-icon ${user?.activity_status?.toLowerCase() == 'online' ? 'online-u' : user?.activity_status?.toLowerCase() == 'donotdisturb' ? 'donotdisturb-u' : 'offline-u'}`
                } />
              {/* <div className="assigned-title-info">
                <article>
                  <figure className="bgBlue">
                    <div className="user-drop-down-img">
                      {!user.photo ? InitalRender(user.first_name + " " + user.last_name) :
                        <img src={user.photo} onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.closest('.bgBlue').append(InitalRender(originalValues(user.first_name + " " + user.last_name, 23)));
                          currentTarget.remove();
                        }} />}
                      <span className="portal-status-icon online-u"></span>
                    </div>
                  </figure>
                  <figcaption>
                    <h4>{originalValues(
                      user.first_name + " " + user.last_name
                    )}</h4>
                    <p>
                      <i className="fal fa-envelope"></i>{" "}
                      <a className="hover-color-blue" onClick={copyEmailText}>{user.email}</a>
                      {copyText ? (
                        <i className="fas fa-check-circle cat-greenhex"></i>
                      ) : (
                        <i className="fal fa-copy hover-color-blue" onClick={copyEmailText}
                        >
                        </i>
                      )}
                    </p>
                  </figcaption>
                </article>
              </div> */}
            </div>
          </div>


          <div className="post-area-right " ref={editorRef}>
            {editorShow ?
              <div className="post-input-block" onDragEnter={handleDragEnter}>
                <HtmlInputEditor
                  editorState={editorState}
                  setEditorState={setEditorState}
                  isInValid={editorState}
                  data={users}
                  placeholder='Start a new conversation with your class'
                  attachmentClick={handleAttahment}
                  sendClick={handleBtnMessage}
                  showToggle={true}
                  resetFeed={resetFeed}
                  subjectLine={subjectLine}
                  updateSubjectLine={(e) => updateSubjectLine(e)}
                  attachments={attachments}
                  setIsImportant={setIsImportant}
                  isImportant={isImportant}
                  // showEditor={showEditor}
                  // setShowEditor={setShowEditor}
                  showcurservalue={showcurvalue}
                />

                <input
                  type="file"
                  ref={btnAttachment}
                  style={{ display: "none" }}
                  onChange={(e) => handleFiles(e)}
                />
                {inputError != '' ? <div className="invalid-feedback d-block">{inputError}</div> : null}

                <div className={!showFilePond ? 'hideFilePond' : 'showFilePond'}>

                  <FilePond
                    name="post"
                    allowMultiple={true}
                    maxFiles={3}
                    ref={filePondRef}
                    files={files}
                    onupdatefiles={setFiles}
                    beforeRemoveFile={(f) => { setRemoveFile(f.file.name); attachmentsArrUpdate(f.file.name) }}
                    init={handleFilePondInit}
                    onaddfile={(err, fileItem) => {
                      setShowFilePond(true);

                    }}
                    server=
                    {
                      {
                        url: API_BASE,
                        process: {
                          url: '/addFeedAttachments',
                          headers: {
                            'Authorization': `Bearer ${getToken()}`
                          },
                          onload: (response) => {
                            handleUploadedFiles(response);
                          },
                          ondata: (formData) => {
                            formData.append('datasize', true);
                            return formData;
                          },
                        },
                        revert: {

                          url: `/removeFeedAttachments/${removeFile}`,
                          headers: {
                            'Authorization': `Bearer ${getToken()}`
                          },
                          onload: (response) => {
                            setRemoveFile(null);
                          }
                        }

                      }
                    }

                  />
                </div>
              </div>
              :
              <button className="btn btn-primary new_discussion" title="New Discussion"><i className="fal fa-plus"></i>New Discussion</button>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(FeedMessageForm);